@charset 'UTF-8';

.top {
	&__title {
		text-align: center;
		@include font-size(26);
		line-height: 1.15em;
		color: #1E1E1E;
		letter-spacing: 0.04em;
		font-weight: 600;
		@include min-screen(768px) {
			@include font-size(22);
		}
		@include min-screen(992px) {
			@include font-size(26);
		}
		@include max-screen(767px) {
			@include font-size(19);
		}
	}
	&__mainvisual {
		position: relative;
		.main-img {
			line-height: 0;
			img {
				width: 100%;
			}
		}
		h1 {
			@include font-size(26);
			color: #1E1E1E;
			letter-spacing: 0.15em;
			line-height: 1.46em;
			text-align: center;
			position: absolute;
			top: 150px;
			left: 0;
			width: 100%;
			font-weight: 600;
			@include min-screen(768px) {
				top: 50px;
			}
			@include min-screen(992px) {
				top: 150px;
			}
			@include max-screen(767px) {
				@include font-size(17);
				line-height: 1.52em;
				top: 55px;
			}
		}
	}
	&__boxLink {
		background: #F7F7F7;
		ul {
			@include clearfix();
			display: table;
			width: 100%;
			li {
				display: table-cell;
				vertical-align: middle;
				border-right: 1px solid rgba(149,152,154,0.2);
				&:last-child {
					border-right: none;
				}
				a {
					@include font-size(13);
					color: #1E1E1E;
					display: block;
					text-align: center;
					font-weight: 600;
					line-height: 1.23em;
					padding: 15px 0;
				}
			}
		}
	}
	&__block01 {
		@include clearfix();
		//display: table;
		display: none;
		width: 100%;
		height: 388px;
		@include max-screen(767px) {
			height: auto;
			display: none;
			padding: 35px 0 0;
		}
		&__boxText {
			width: 50%;
			display: table-cell;
			vertical-align: middle;
			padding: 0 0 0 15px;
			@include max-screen(767px) {
				display: block;
				width: 100%;
				padding: 0 20px 25px;
				position: relative;
				&:before {
					content: '';
					background: url('../img/top/top_img_01_sp.png') no-repeat center bottom;
					background-size: cover;
					height: 280px;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					z-index: -1;
				}
			}
			&__wrap {
				float: right;
				@include max-screen(767px) {
					float: none;
				}
				h3 {
					@include font-size(20);
					color: #1E1E1E;
					line-height: 1.8em;
					letter-spacing: 0.05em;
					font-weight: 600;
					@include min-screen(768px) {
						@include font-size(17);
					}
					@include min-screen(992px) {
						@include font-size(20);
					}
					@include max-screen(767px) {
						@include font-size(17);
						line-height: 1.52em;
					}
				}
				.txt {
					@include font-size(15);
					color: #1E1E1E;
					line-height: 1.86em;
					letter-spacing: 0.022em;
					margin: 20px 0 0;
					@include min-screen(768px) {
						@include font-size(14);
					}
					@include min-screen(992px) {
						@include font-size(15);
					}
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 1.71em;
						margin: 10px 0 0;
					}
				}
				&__btn {
					margin: 20px 0 0;
					@include max-screen(767px) {
						margin: 150px auto 0;
					}
					a {
						@include font-size(14);
						@include max-screen(767px) {
							@include font-size(13);
						}
					}
				}
			}
		}
		&__boxImg {
			width: 50%;
			display: table-cell;
			vertical-align: middle;
			background: url('../img/top/top_img_01_pc.png') no-repeat left top;
			background-size: cover;
			line-height: 0;
			@include max-screen(767px) {
				display: none;
				width: 100%;
				background: none;
			}
			figure {
				overflow: hidden;
				img {
					width: 100%;
				}
			}
		}
	}
	&__house {
		padding: 0;
		@include max-screen(767px) {
			padding: 0;
			border-bottom: 1px solid #E6E6E6;
		}
		&__houseList {
			@include clearfix();
			margin: 0 -1px 0;
			&__item {
				padding: 0 1px;
				overflow: hidden;
				position: relative;
				&:nth-child(1) {
					&:after {
						background: rgba(54,207,198,0.4);
					}
				}
				&:nth-child(2) {
					&:after {
						background: rgba(250,159,12,0.4);
					}
				}
				&:nth-child(3) {
					&:after {
						background: rgba(37,189,231,0.4);
					}
				}
				&:nth-child(4) {
					&:after {
						background: rgba(250,134,175,0.4);
					}
				}
				&:after {
					content: '';
					opacity: 0;
					text-align: center;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 50%;
					@include transition(0.4s);
					overflow: hidden;
				}
				&:hover {
					&:after {
						@include translate(0,0);
						opacity: 1;
						width: 100%;
					}
				}
				h4 {
					background: #000;
					@include font-size(17);
					line-height: 1.35em;
					color: #fff;
					text-align: center;
					height: 72px;
					font-weight: 600;
					padding: 16px 10px;
					letter-spacing: 0.02em;
					position: relative;
					z-index: 2;
					@include min-screen(768px) {
						@include font-size(14);
					}
					@include min-screen(992px) {
						@include font-size(17);
					}
					@include max-screen(767px) {
						@include font-size(14);
						height: auto;
						padding: 9px 10px;
					}
					@include max-screen(320px) {
						@include font-size(12);
					}
				}
				figure {
					line-height: 0;
					img {
						width: 100%;
					}
					a {
						display: block;
						width: 100%;
						position: relative;
					}
				}
				&__btn {
					height: 72px;
					line-height: 72px;
					background: #F7F7F7;
					@include font-size(19);
					font-weight: 600;
					text-align: center;
					display: block;
					color: #1E1E1E;
					letter-spacing: 0.04em;
					@include min-screen(768px) {
						@include font-size(16);
					}
					@include min-screen(992px) {
						@include font-size(19);
					}
					@include max-screen(767px) {
						@include font-size(16);
						height: 52px;
						line-height: 52px;
					}
					span {
						position: relative;
						padding: 0 20px 0 0;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b2.svg') no-repeat 0 0;
							background-size: 8px 11px;
							width: 8px;
							height: 11px;
							top: 50%;
							right: 0;
							margin-top: -5.5px;
							position: absolute;
						}
					}
				}
				&:nth-child(1) {
					h4 {
						background: #36CFC6;
					}
				}
				&:nth-child(2) {
					h4 {
						background: #FA9F0C;
					}
				}
				&:nth-child(3) {
					h4 {
						background: #25BDE7;
					}
				}
				&:nth-child(4) {
					h4 {
						background: #FA86AF;
					}
				}
			}
		}
	}
	&__modelHouse {
		padding: 0 0 80px;
		@include max-screen(767px) {
			padding: 0 0 40px 0;
		}
		&__wrapItem {
			@include clearfix();
			margin: 20px 0 0;
			width: 100%;
			@include max-screen(767px) {
				margin: 0;
			}
			.bnr {
				margin: 35px 0 0;
				@include max-screen(767px) {
					padding: 0 30px;
					margin: 20px 0 0;
				}
				line-height: 0;
				a {
					display: block;
					line-height: 0;
					img {
						&.on {
							display: none;
						}
					}
					&:hover {
						img {
							&.on {
								display: block;
							}
							&.off {
								display: none;
							}
						}
					}
				}
			}
			&__boxImg {
				float: left;
				width: 50%;
				position: relative;
				@include max-screen(767px) {
					float: none;
					width: 100%;
					margin: -160px 0 0;
					padding: 0 15px;
				}
				ul {
					li {
						line-height: 0;
						font-size: 0;
						img {
							width: 100%;
						}
					}
					&.img-large {
						@include max-screen(767px) {
							margin: 0 -15px 0 0;
						}
						li {
							img {
								width: 100%;
							}
						}
					}
					&.img-thumb {
						margin: 25px 0 0;
						@include clearfix();
						@include max-screen(767px) {
							margin: 20px -6px 0;
						}
						li {
							float: right;
							margin: 0 0 0 20px;
							width: 35%;
							@include max-screen(767px) {
								width: 50%;
								margin: 0;
								padding: 0 6px;
							}
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
			&__boxText {
				float: right;
				width: 55%;
				position: relative;
				margin-left: -5%;
				@include max-screen(767px) {
					float: none;
					width: 100%;
					margin-left: 0;
				}
				&__wrap {

					&__item {
						background: #36CFC6;
						padding: 6% 0 6% 15%;
						@include max-screen(767px) {
							padding: 30px 15px 180px;
						}
						&__inner {
							max-width: 454px;
							@include min-screen(768px) {
								max-width: 340px;
							}
							@include min-screen(992px) {
								max-width: 440px;
							}
							@include min-screen(1200px) {
								max-width: 454px;
							}
						}
						h4 {
							@include font-size(20);
							line-height: 1.6em;
							color: #fff;
							font-weight: 600;
							letter-spacing: 0.05em;
							@include min-screen(768px) {
								@include font-size(16);
							}
							@include min-screen(992px) {
								@include font-size(20);
							}
							@include max-screen(767px) {
								@include font-size(17);
								line-height: 1.52em;
							}
						}
						.txt {
							color: #fff;
							@include font-size(15);
							line-height: 1.86em;
							margin: 20px 0 0;
							@include min-screen(768px) {
								@include font-size(12);
							}
							@include min-screen(992px) {
								@include font-size(15);
							}
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 1.71em;
								margin: 10px 0 0;
							}
						}
						.link {
							@include font-size(14);
							font-weight: 600;
							color: #fff;
							border-top: 1px solid #fff;
							padding: 10px 0 0;
							margin: 25px 0 0;
							@include min-screen(768px) {
								@include font-size(12);
							}
							@include min-screen(992px) {
								@include font-size(14);
							}
							@include max-screen(767px) {
								margin: 20px 0 0;
								padding: 20px 0 0;
								line-height: 1.71em;
							}
							a {
								text-decoration: underline;
								color: inherit;
							}
						}
					}
					.img-txt {
						background: #fff;
						padding: 30px 0 40px 15%;
						@include min-screen(768px) {
							padding: 15px 0 20px 15%;
						}
						@include min-screen(992px) {
							padding: 30px 0 40px 15%;
						}
						@include max-screen(767px) {
							text-align: center;
							padding: 20px 0;
							img {
								max-width: 186px;
							}
						}
					}
				}
			}
		}
	}
	&__results {
		padding: 80px 0;
		@include max-screen(767px) {
			padding: 40px 0 50px;
		}
		&__wrapItem {
			@include clearfix();
			margin: 25px -1px 0;
			@include max-screen(767px) {
				margin: 20px -1px 0;
			}
			&__item {
				padding: 0 1px;
				@include max-screen(767px) {
					display: none;
					&:nth-child(1),
					&:nth-child(2) {
						display: block;
					}
				}
				&:hover {
					figure {
						a {
							&:before {
								opacity: 1;
								@include translate(0,0);
							}
							&:after {
								@include translate(0,0);
								opacity: 1;
								width: 100%;
							}
						}
					}
				}
				figure {
					line-height: 0;
					a {
						display: block;
						position: relative;
						@include max-screen(767px) {
							background: none !important;
							height: auto;
						}
						&:after {
							content: '';
							opacity: 0;
							text-align: center;
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 50%;
							background: rgba(0,0,0,0.4);
							@include transition(0.4s);
							overflow: hidden;
						}
					}
				}
				.boxInfo {
					padding: 15px 20px;
					@include max-screen(767px) {
						padding: 10px 15px;
					}
					h4 {
						@include font-size(15);
						line-height: 1.73em;
						letter-spacing: 0.02em;
						font-weight: 600;
						@include min-screen(768px) {
							@include font-size(14);
						}
						@include min-screen(992px) {
							@include font-size(15);
						}
					}
					p {
						@include font-size(12);
						letter-spacing: 0.02em;
						line-height: 1.5em;
						margin: 3px 0 0;
					}
				}
			}
		}
		&__btn {
			margin: 25px auto 0;
			@include max-screen(767px) {
				margin: 10px auto 0;
			}
		}
	}
	&__blogNews {
		background: #F7F7F7;
		padding: 60px 0;
		@include max-screen(767px) {
			padding: 0;
			border-top: 1px solid #E6E6E6;
			background: none;
		}
		&__wrapItem {
			@include clearfix();
			margin: 0 -20px;
			&__boxItem {
				padding: 0 20px;
				&__boxTitle {
					position: relative;
					padding: 0 40px 0 0;
					@include min-screen(768px) {
						padding: 0 20px 0 0;
					}
					@include min-screen(992px) {
						padding: 0 40px 0 0;
					}
					@include max-screen(767px) {
						padding: 0 106px 0 0;
					}
					h3 {
						@include font-size(22);
						font-weight: 600;
						line-height: 1.36em;
						letter-spacing: 0.04em;
						@include min-screen(768px) {
							@include font-size(20);
						}
						@include min-screen(992px) {
							@include font-size(22);
						}
						@include max-screen(767px) {
							@include font-size(19);
							line-height: 1.57em;
						}
					}
				}
				&__itemNews {
					&__item {
						@include clearfix();
						margin: 15px 0 0;
						&__boxImg {
							float: left;
							figure {
								line-height: 0;
								width: 102px;
								@include max-screen(767px) {
									width: 74px;
								}
							}
						}
						&__boxText {
							float: left;
							width: calc(100% - 102px);
							padding: 5px 40px 0 20px;
							@include min-screen(768px) {
								padding: 5px 20px 0 20px;
							}
							@include min-screen(992px) {
								padding: 5px 40px 0 20px;
							}
							@include max-screen(767px) {
								width: calc(100% - 74px);
								padding: 0 15px 0 10px;
							}
							h4 {
								@include font-size(14);
								font-weight: 600;
								line-height: 1.7em;
								@include min-screen(768px) {
									@include font-size(13);
								}
								@include min-screen(992px) {
									@include font-size(14);
								}
								@include max-screen(767px) {
									@include font-size(12);
								}
							}
							.cont {
								margin: 5px 0 0;
								@include max-screen(767px) {
									margin: 0;
								}
								p {
									@include font-size(14);
									line-height: 1.57em;
									letter-spacing: 0.02em;
									@include min-screen(768px) {
										@include font-size(12);
									}
									@include min-screen(992px) {
										@include font-size(14);
									}
									@include max-screen(767px) {
										@include font-size(14);
										overflow: hidden;
									}
								}
							}
						}
					}
				}
				&__btn {
					max-width: 106px;
					width: 100%;
					position: absolute;
					top: 0;
					right: 40px;
					@include min-screen(768px) {
						right: 20px;
					}
					@include min-screen(992px) {
						right: 40px;
					}
					@include max-screen(767px) {
						right: 0;
					}
					a {
						@include font-size(12);
						color: #fff;
						text-align: center;
						display: block;
						background: #B3B3B3;
						line-height: 30px;
						position: relative;
						padding: 0 10px 0 0;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							right: 10px;
							margin-top: -4px;
						}
					}
				}
				&--item1 {
					@include max-screen(767px) {
						background: #fff;
						padding: 30px 15px;
					}
				}
				&--item2 {
					@include max-screen(767px) {
						background: #F7F7F7;
						padding: 35px 15px;
					}
				}
			}
		}
	}
	&__blog {
		border-bottom: 1px solid #E6E6E6;
		@include max-screen(767px) {
			padding: 45px 0 40px;
			border-bottom: none;
		}
		&__wrap {
			display: table;
			width: 100%;
			padding: 50px 0 40px;
			@include max-screen(767px) {
				display: block;
				padding: 30px 30px 20px;
				border: 1px solid #E6E6E6;
			}
			&__boxTitle {
				display: table-cell;
				vertical-align: middle;
				border-right: 1px solid #585858;
				width: 140px;
				@include max-screen(767px) {
					display: block;
					width: 100%;
					border: none;
					text-align: center;
				}
				h3 {
					@include font-size(22);
					font-weight: 600;
					line-height: 1.36em;
					letter-spacing: 0.04em;
					@include min-screen(768px) {
						@include font-size(20);
					}
					@include min-screen(992px) {
						@include font-size(22);
					}
					@include max-screen(767px) {
						@include font-size(19);
					}
				}
			}
			&__boxContent {
				display: table-cell;
				vertical-align: middle;
				width: calc(100% - 140px);
				padding: 0 0 0 50px;
				@include min-screen(768px) {
					padding: 0 0 0 25px;
				}
				@include min-screen(992px) {
					padding: 0 0 0 50px;
				}
				@include max-screen(767px) {
					display: block;
					width: 100%;
					padding: 0;
				}
				&__item {
					padding: 5px 0;
					&:last-child {
						@include max-screen(767px) {
							display: none;
						}
					}
					h4 {
						display: inline-block;
						@include font-size(14);
						font-weight: 600;
						line-height: 1.7em;
						@include min-screen(768px) {
							@include font-size(13);
						}
						@include min-screen(992px) {
							@include font-size(14);
						}
						@include max-screen(767px) {
							@include font-size(12);
						}
					}
					.cont {
						display: inline-block;
						@include max-screen(767px) {
							display: block;
						}
						p {
							@include font-size(14);
							line-height: 1.7em;
							@include min-screen(768px) {
								@include font-size(13);
							}
							@include min-screen(992px) {
								@include font-size(14);
							}
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 1.7em;
							}
						}
					}
				}
			}
		}
	}
	&__bottomContent {
		&__wrapItem {
			padding: 30px 0 70px;
			@include min-screen(768px) {
				padding: 30px 0 35px;
			}
			@include min-screen(992px) {
				padding: 30px 0 70px;
			}
			@include max-screen(767px) {
				padding: 0 0 15px;
				margin: -45px 0 0;
			}
			&__item {
				@include clearfix();
				margin: 70px 0 0;
				@include min-screen(768px) {
					margin: 35px 0 0;
				}
				@include min-screen(992px) {
					margin: 70px 0 0;
				}
				@include max-screen(767px) {
					margin: 45px 0 0;
				}
				&__boxText {
					padding: 0;
					width: 60%;
					@include max-screen(767px) {
						width: 100%;
					}
					h4 {
						@include font-size(20);
						font-weight: 600;
						line-height: 1.8em;
						letter-spacing: 0.04em;
						margin: -10px 0 0;
						@include min-screen(768px) {
							@include font-size(17);
						}
						@include min-screen(992px) {
							@include font-size(20);
						}
						@include max-screen(767px) {
							@include font-size(17);
							line-height: 1.52em;
							margin: 0;
						}
					}
					.cont {
						margin: 15px 0 0;
						p.txt {
							@include font-size(15);
							line-height: 1.86em;
							letter-spacing: 0.02em;
							text-align: justify;
							@include min-screen(768px) {
								@include font-size(13);
							}
							@include min-screen(992px) {
								@include font-size(15);
							}
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 1.7em;
								height: 90px;
								overflow: hidden;
								@include transition(all 0.4s);
								&.active {
									height: auto;
								}
							}
							a {
								text-decoration: underline;
							}
						}
					}
					&--item1 {
						float: right;
						padding: 0 0 0 40px;
						@include max-screen(767px) {
							float: none;
							padding: 0;
						}
					}
					&--item2 {
						float: left;
						padding: 0 40px 0 0;
						@include max-screen(767px) {
							float: none;
							padding: 0;
						}
					}
				}
				&__boxImg {
					padding: 0;
					width: 40%;
					@include max-screen(767px) {
						width: 100%;
						margin: 20px 0 0;
					}
					figure {
						line-height: 0;
						img {
							width: 100%;
						}
					}
					&--item1 {
						float: left;
						@include max-screen(767px) {
							float: none;
						}
					}
					&--item2 {
						float: right;
						@include max-screen(767px) {
							float: none;
						}
					}
				}
				&__btn {
					max-width: 136px;
					margin: 0 auto;
					display: none;
					@include max-screen(767px) {
						display: block;
						margin: 10px auto 0;
					}
					a {
						@include font-size(12);
						line-height: 30px;
						text-align: center;
						letter-spacing: 0.02em;
						display: block;
						background: #B3B3B3;
						position: relative;
						color: #fff;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							right: 7px;
							margin-top: -4px;
							@include rotate(90);
							@include transition(all 0.3s);
						}
						span {
							&.r-close {
								display: none;
							}
						}
						@include max-screen(767px) {
							letter-spacing: 0;
							&.active {
								&:before {
									@include rotate(270);
								}
								span {
									&.r-close {
										display: block;
									}
									&.r-more {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

