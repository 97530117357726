.blog-news {
	&__detail {
		&__inner {
			max-width: 840px;
			margin: 65px auto 0;
			@include max-screen(767px) {
				margin: 30px auto 0;
			}
		}
		&__boxImg {
			position: relative;
			figure {
				line-height: 0;
			}
			.cate-label {
				position: absolute;
				top: 0;
				left: 0;
				@include font-size(21);
				line-height: 41px;
				text-align: center;
				display: inline-block;
				color: #fff;
				padding: 0 11px;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 27px;
				}
				@include max-screen(320px) {
					@include font-size(12);
				}
			}
			.date {
				@include font-size(12);
				line-height: 1.5em;
				font-weight: 600;
				margin: 20px 0 0;
			}
		}
		&__boxInfo {
			margin: 60px 0 0;
			@include max-screen(767px) {
				margin: 0;
			}
			.h2_cms {
				border-bottom: 1px solid #1594B0;
				padding: 0 0 15px;
				@include max-screen(767px) {
					margin: 10px 0 0;
					border-bottom: none;
					padding: 0;
				}
			}
		}
		&__boxContent {
			margin:0;
			@include max-screen(767px) {
				border-top: 1px solid #E6E6E6;
				margin: 20px 0 0;
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				margin: 20px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.71em;
				}
				img {
					max-width: 700px;
					@include max-screen(767px) {
						max-width: 100%;
						width: 100%;
					}
					&.vt-img {
						max-height: 700px;
						max-width: 100%;
						@include max-screen(767px) {
							max-height: auto;
							width: 100%;
						}
					}
				}
			}
			h2 {
				@include font-size(22);
				font-weight: 600;
				line-height: 1.45em;
				text-align: left;
				margin: 35px 0 0;
				color: #1E1E1E;
				@include max-screen(767px) {
					@include font-size(19);
					line-height: 1.57em;
					margin: 20px 0 0;
				}
			}
			h3 {
				@include font-size(17);
				color: #1E1E1E;
				line-height: 1.52em;
				font-weight: 600;
				border-bottom: 1px solid #1594B0;
				padding: 0 0 10px;
				margin: 30px 0 0;
				@include max-screen(767px) {
					margin: 20px 0 0;
				}
			}
			h4 {
				@include font-size(20);
				line-height: 1.4em;
				font-weight: 600;
				color: #1E1E1E;
				position: relative;
				padding: 0 0 0 20px;
				margin: 45px 0 0;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.3em;
					padding: 0 0 0 15px;
					margin: 40px 0 0;
				}
				&:before {
					content: '';
					width: 4px;
					height: 26px;
					background: #1594B0;
					position: absolute;
					top: 0;
					left: 0;
					@include max-screen(767px) {
						height: 100%;
					}
				}
			}
			h5 {
				@include font-size(18);
				color: #1594B0;
				line-height: 1.22em;
				font-weight: 600;
				margin: 40px 0 0;
				@include max-screen(767px) {
					@include font-size(15);
					line-height: 1.46em;
					margin: 30px 0 0;
				}
			}
			.h3_cms {
				@include font-size(20);
				@include max-screen(767px) {
					@include font-size(17);
				}
			}
		}
	}
}