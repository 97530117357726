.results {
	.footer {
		@include max-screen(767px) {
			margin: 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/results/results_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/results/results_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__article {
		&__articleWrap {
			@include clearfix();
			margin: 0 -10px 0;
			@include max-screen(767px) {
				margin: 0 -16px 0;
			}
			&__item {
				padding: 0 10px;
				margin: 65px 0 0;
				@include max-screen(767px) {
					padding: 0 1px;
					margin: 30px 0 0;
				}
				&__boxImg {
					position: relative;
					figure {
						line-height: 0;
						img {
							width: 100%;
						}
					}
					.box-cate {
						position: absolute;
						top: 0;
						left: 0;
						.cate-label {
							@include font-size(14);
							line-height: 27px;
							text-align: center;
							float: left;
							color: #fff;
							padding: 0 13px;
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 27px;
							}
							@include max-screen(320px) {
								@include font-size(12);
							}
						}
					}	
				}
				&__boxInfo {
					padding: 10px 0 0;
					@include max-screen(767px) {
						padding: 10px 15px 0;
					}
					h3 {
						@include font-size(15);
						font-weight: 600;
						line-height: 1.46em;
						letter-spacing: 0.04em;
					}
					p {
						@include font-size(12);
						line-height: 1.5em;
						margin: 3px 0 0;
					}
				}
			}
		}
	}
}