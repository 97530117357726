.service {
	.footer {
		@include max-screen(767px) {
			margin: 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/service/img_main.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/service/service_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__article {
		&__boxInfo {
			margin: 40px 0 0;
			padding: 0 0 60px;
			@include max-screen(767px) {
				margin: 25px 0 0;
				padding: 0 0 30px;
			}
			h2 {
				@include font-size(26);
				line-height: 1.3em;
				font-weight: 600;
				letter-spacing: 0.04em;
				text-align: center;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.52em;
					text-align: left;
				}
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				text-align: center;
				margin: 25px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					text-align: left;
					line-height: 1.7em;
					margin: 15px 0 0;
				}
			}
		}
		&__boxItem {
			background: #F7F7F7;
			padding: 25px 0 75px;
			@include max-screen(767px) {
				padding: 10px 0 40px;
			}
			&__wrap {
				@include clearfix();
				margin: 0 -20px;
				@include max-screen(767px) {
					margin: 0;
				}
				&__item {
					padding: 0 20px;
					margin: 50px 0 0;
					position: relative;
					@include max-screen(767px) {
						margin: 30px 0 0;
						padding: 0;
					}
					.boxInner {
						position: relative;
						&:after {
							content: '';
							opacity: 0;
							text-align: center;
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 50%;
							@include transition(0.4s);
							overflow: hidden;
						}
						&:hover {
							&:after {
								@include translate(0,0);
								opacity: 1;
								width: 100%;
							}
						}
					}
					&:nth-child(1) {
						.boxInner {
							&:after {
								background: rgba(54,207,198,0.4);
							}
						}
					}
					&:nth-child(2) {
						.boxInner {
							&:after {
								background: rgba(250,159,12,0.4);
							}
						}
					}
					&:nth-child(3) {
						.boxInner {
							&:after {
								background: rgba(37,189,231,0.4);
							}
						}
					}
					&:nth-child(4) {
						.boxInner {
							&:after {
								background: rgba(250,134,175,0.4);
							}
						}
					}
					
					h3 {
						@include font-size(20);
						line-height: 66px;
						text-align: center;
						font-weight: 600;
						background: #fff;
						@include max-screen(767px) {
							@include font-size(17);
							line-height: 46px;
						}
						&.item1 {
							border-top: 4px solid #36CFC6;
						}
						&.item2 {
							border-top: 4px solid #FA9F0C;
						}
						&.item3 {
							border-top: 4px solid #25BDE7;
						}
						&.item4 {
							border-top: 4px solid #FA86AF;
						}
					}
					figure {
						line-height: 0;
						img {
							width: 100%;
						}
					}
					&__info {
						padding: 40px 30px 35px;
						position: relative;
						z-index: 2;
						@include max-screen(767px) {
							padding: 25px 20px 20px;
						}
						h4 {
							@include font-size(19);
							line-height: 1.4em;
							color: #fff;
							text-align: left;
							font-weight: 600;
							letter-spacing: 0.04em;
							@include max-screen(767px) {
								@include font-size(15);
								line-height: 1.46em;
							}
						}
						p {
							@include font-size(14);
							line-height: 1.7em;
							color: #fff;
							text-align: justify;
							margin: 20px 0 0;
							@include max-screen(767px) {
								@include font-size(13);
								line-height: 1.53em;
								margin: 10px 0 0;
							}
						}
					}
					&__info1 {
						background: #36CFC6;
					}
					&__info2 {
						background: #FA9F0C;
					}
					&__info3 {
						background: #25BDE7;
					}
					&__info4 {
						background: #FA86AF;
					}
				}
			}
		}
		&__boxBtn {
			padding: 65px 0 100px;
			@include max-screen(767px) {
				padding: 50px 0;
			}
			&__wrap {
				max-width: 620px;
				margin: 0 auto;
				.ttl {
					text-align: center;
					@include font-size(15);
					line-height: 1.46em;
					font-weight: 600;
					@include max-screen(320px) {
						@include font-size(13);
					}
					span {
						position: relative;
						padding: 0 30px;
						display: inline-block;
						&:before {
							content: '';
							background: url('../img/common/ico_line.svg') no-repeat 0 0;
							background-size: 18px 39px;
							width: 18px;
							height: 39px;
							position: absolute;
							top: 0;
							left: 0;
							@include max-screen(320px) {
								top: -5px;
							}
						}
						&:after {
							content: '';
							background: url('../img/common/ico_line.svg') no-repeat 0 0;
							background-size: 18px 39px;
							width: 18px;
							height: 39px;
							position: absolute;
							top: 0;
							right: 0;
							@include rotate(405);
							@include max-screen(320px) {
								top: -5px;
							}
						}
					}
				}
			}
			&__btn {
				margin: 10px auto 0;
				text-align: center;
				a {
					display: block;
					line-height: 0;
					img {
						line-height: 0;
						font-size: 0;
						margin: 0 auto;
						&.img-on {
							display: none;
						}
					}
					&:hover {
						img {
							&.img-on {
								display: block;
							}
							&.img-off {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}