.notfound {
	.subpage {
		&__mainvisual {
			background: url('../img/blog-news/blog-news_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/blog-news/blog-news_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__article {
		padding: 0 0 90px;
		@include max-screen(767px) {
			padding: 30px 0;
		}
	}
	.text {
		text-align: center;
		@include font-size(16);
		padding: 20px 0;
	}
	.btnEffect {
		margin: 0 auto;
	}
}