@charset 'UTF-8';

.myAn {
	width: 100%;
	height: 0;
	font-size: 0;
	margin-top: -30px;
	padding-top: 30px;
}

#casacube {
	@include max-screen(767px) {
		.mt0 {margin-top: 0px!important;}
	}
	main {
		img {
			vertical-align: middle;
		}
		.nobor { border: none; } 
	}
	.col2 {
		margin-bottom: 40px;
		> li {
			.desc p {
				.green {
					color: #36CFC6;
					font-weight: bold;
					font-style: normal;
				}
			}
		}
		> li:nth-child(2n+1) {
			.desc p:not(.img) {
				padding-right: 10px;
			}
		}
	}
	.itemService {
		.thumb { background: none; }
		h4 {
			font-weight: bold;
			font-size: 18px;
			line-height: 1.6;
			margin: 0 0 8px;
		}
		.fullW {
			width: 100%;
			margin-bottom: 50px;
		}
	}
	.bnrNav {
		margin-top: 0;
	}
	.footer__boxContact {
		display: none;
	}
	.contact_box .inner {
		padding-bottom: 15px;
		ul li:nth-child(1) img {
			margin-top: -78px;
			@include max-screen(767px) {
				margin-top: 0;
			}
		}
	}
	.leadBox .txtBox {
		max-width: 670px;
		ul {
			padding-left: 45px;
			padding-right: 30px;
			li {
				vertical-align: top;
				width: 53%;
			}
			li:nth-child(2n+1) {
				width: 47%;
			}
		}
	}
	@include max-screen(767px) {
		.bHead {
			margin-bottom: 20px;
		}
		.sHead1 {
			font-size: 16px;
		}
		.leadBox .txtBox {
			ul {
				padding-left: 15px;
				padding-right: 15px;
				li {
					width: 100%;
				}
				li:nth-child(2n+1) {
					width: 100%;
				}
			}
		}
		.leadBox {
			.anchorList {
				li {
					a {
						span {
							padding-right: 0;
						}
					}
				}
			}
		}
		.itemService {
			.thumb {
				img { width: 100%; }
			}
			h4 {
				font-size: 15px;
				margin-bottom: 5px;
			}
			.fullW {
				margin-bottom: 0;
			}
		}
	}
	@include max-screen(374px) {
		.leadBox {
			.anchorList {
				li {
					a {
						font-size: 3.4vw;
						span {
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
}

.ssHead1 {
	color: #36CFC6;
	font-size: 18px;
	line-height: 1.6;
	font-weight: bold;
	letter-spacing: 0.5px;
	text-align: center;
	margin-bottom: 20px;
	span {
		display: inline-block;
		padding: 0 12px 0 18px;
		position: relative;
		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 1px;
			height: 36px;
			margin-top: -18px;
			background: #36CFC6;
			-moz-transform: rotate(-30deg);
			-webkit-transform: rotate(-30deg);
			-ms-transform: rotate(-30deg);
			transform: rotate(-30deg);
		}
		&:after {
			left: auto;
			right: 0;
			-moz-transform: rotate(30deg);
			-webkit-transform: rotate(30deg);
			-ms-transform: rotate(30deg);
			transform: rotate(30deg);
		}
	}
	@include max-screen(767px) {
		font-size: 14px;
		letter-spacing: 0;
		margin-bottom: 10px;
		span {
			padding: 0 10px 0 13px;
			&:before, &:after {
				height: 26px;
				margin-top: -13px;
			}
		}
	}
}

.ssHead2 {
	font-size: 19px;
	font-weight: bold;
	line-height: 1.6;
	margin-bottom: 15px;
	.num {
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		font-family: 'Helvetica Neue';
		width: 30px;
		height: 30px;
		padding-top: 2px;
		margin: -6px 10px 0 0;
		text-align: center;
		background: #36CFC6;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		-ms-border-radius: 50%;
		border-radius: 50%;
	}
	@include max-screen(767px) {
		font-size: 16px;
		cursor: pointer;
		position: relative;
		&:before, &:after {
			display: block;
			content: '';
			position: absolute;
			right: 10px;
			top: 50%;
			width: 12px;
			height: 2px;
			margin-top: -2px;
			background: #B3B3B3;
		}
		&:after {
			-moz-transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		&.active {
			&:after {
				display: none;
			}
		}
		.num {
			margin-right: 8px;
		}
	}
}

.chosenSet {
	margin-bottom: 30px;
	@include max-screen(767px) {
		margin-bottom: 10px;
	}
}

.chosenbox {
	border-top: 1px solid #E6E6E6;
	padding: 50px 0 25px;
	margin-bottom: 0;
	&:first-child {
		border: none;
		padding-top: 0;
	}
	h5 {
		font-size: 15px;
		font-weight: bold;
		line-height: 1.6;
		margin-bottom: 5px;
	}
	.chosen {
		margin: 0 -6px;
		li {
			display: inline-block;
			vertical-align: top;
			width: 16.6666666667%;
			padding: 0 6px;
			margin-bottom: 25px;
			text-align: center;
			span {
				display: block;
				font-size: 12px;
				line-height: 1.4;
				margin-top: 10px;
			}
		}
	}
	.note {
		font-size: 12px;
	}
	@include max-screen(767px) {
		padding: 18px 0 0;
		margin-bottom: 0;
		&:first-child {
			border-top: 1px solid #E6E6E6;
			padding-top: 18px;
		}
		.chosen {
			padding-bottom: 10px;
			margin: 0 -2px;
			display: none;
			li {
				width: 33.3333333333%;
				padding: 0 2px;
				margin-bottom: 20px;
				> img { width: 100%; }
			}
		}
	}
}

.col2Set {
	.col2A {
		> li:first-child {
			padding: 5px 0 0;
		}
		> li:last-child {
			margin-bottom: 35px;
		}
	}
	@include max-screen(767px) {
		.col2A {
			> li:first-child {
				border-top: 1px solid #E6E6E6;
				padding-top: 18px;
			}
			> li:last-child {
				margin-bottom: 0;
				.desc {
					padding-bottom: 10px;
				}
			}
		}
	}
}

.col2A {
	> li {
		border-top: 1px solid #E6E6E6;
		padding: 50px 0 25px;
		margin-bottom: 30px;
		&:first-child {
			border: none;
		}
		&:last-child {
			margin-bottom: 55px;
		}
		.img {
			float: left;
			width: 33%;
			margin-right: 30px;
		}
		.desc {
			overflow: hidden;
			font-size: 14px;
			line-height: 1.8;
		}
	}
	.chosenbox {
		border: none;
		padding: 0;
		margin-bottom: 0;
		.chosen {
			margin: 0 -2px;
			li {
				padding: 0 2px;
			}
		}
	}
	@include max-screen(767px) {
		> li {
			padding: 18px 0 0;
			margin-bottom: 0;
			.img {
				float: none;
				width: 100%;
				margin: 0 0 10px;
				img { width: 100%; }
			}
			.desc {
				padding-bottom: 25px;
			}
		}
		> li:last-child {
			margin-bottom: 25px;
		}
		.chosenbox {
			.chosen {
				padding-bottom: 0;
			}
		}
	}
}

.col3Set {
	border-top: 1px solid #E6E6E6;
	border-bottom: 1px solid #E6E6E6;
	padding: 50px 0 0;
	h5 {
		font-size: 15px;
		font-weight: bold;
		line-height: 1.6;
		margin-bottom: 5px;
	}
	.col3 {
		margin-bottom: 0;
		.chosenbox {
			border: none;
			padding: 0;
			margin-bottom: 0;
			.chosen {
				margin: 0 -2px;
				li {
					width: 33.3333333333%;
					padding: 0 2px;
				}
			}
		}
	}
	@include max-screen(767px) {
		padding: 18px 0 0;
		.ssHead2 {
			font-size: 14px;
		}
		.col3 {
			> li {
				padding-bottom: 25px;
				.img {
					margin-bottom: 8px;
				}
			}
			.chosenbox {
				.chosen {
					padding-bottom: 0;
					li {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}


#casacube {
	@include max-screen(767px) {
		.footer__boxLink__linkSP ul.main-link li:first-child {border-top: 1px solid #E6E6E6;}
	}
}

