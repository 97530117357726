@charset "utf-8";



/* argument */
$border1: #c4c6c6 solid 1px;
$colortext: #444;
$colortxt1: #fff;
$colorbg1: #000;
$widthcm: 1000px;

/* site style */
@mixin text-hide{
	overflow: hidden;
	text-indent: -9999px;
}


/* [ opacity ]
-------------------------------------------------*/
@mixin opacity($num){
	-ms-filter: alpha(opacity=$num); /* IE8 */
	opacity: $num/100;
	//display: inline-block\9;
}

/* [ display style ]
-------------------------------------------------*/
@mixin display-table{
	display: table;
	> *{
		display: table-cell;
		vertical-align: middle;
	}
}
@mixin height-match{
	display: block;
	text-align: center;
	> *{
		vertical-align: middle;
		display: inline-block;
	}
	&:before{
		content:"";
		height: 100%;
		vertical-align: middle;
		width: 0;
		display: inline-block;
	}
}


/* [ background-image ]
-------------------------------------------------*/
@mixin bgimg($url,$rep:repeat,$pos:left top,$color:none,$size:auto) {
	background-image: url($url);
	background-repeat: $rep;
	background-position: $pos;
	background-color: $color;
	background-size: $size;
}
