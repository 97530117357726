.blog-news {
	#wrap {
		overflow: inherit;
	}
	.footer {
		@include max-screen(767px) {
			margin: 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/blog-news/blog-news_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/blog-news/blog-news_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__article {
		&__articleWrap {
			max-width: 840px;
			margin: 20px auto 0;
			@include clearfix();
			@include max-screen(767px) {
				margin: 15px 0 0;
			}
			&__item {
				margin: 40px 0 0;
				@include clearfix();
				@include max-screen(767px) {
					margin: 15px 0 0;
				}
				figure {
					float: left;
					width: 220px;
					line-height: 0;
					padding: 0 20px 0 0;
					@include max-screen(767px) {
						width: 84px;
						padding: 0 10px 0 0;
					}
					img {
						width: 100%;
					}
				}
				&__boxInfo {
					float: left;
					width: calc(100% - 220px);
					@include max-screen(767px) {
						width: calc(100% - 84px);
					}
					&__box {
						display: block;
						&__boxCate {
							display: inline-block;
							vertical-align: middle;
							@include max-screen(767px) {
								display: block;
							}
							p {
								color: #fff;
								text-align: center;
								line-height: 18px;
								padding: 0 7px;
								@include font-size(12);
								display: inline-block;
								@include max-screen(767px) {
									display: inline-block;
								}
							}
						}
						&__boxDate {
							display: inline-block;
							vertical-align: middle;
							padding: 0 0 0 10px;
							@include max-screen(767px) {
								display: block;
								padding: 0;
								margin: 5px 0 0;
							}
							p {
								@include font-size(13);
								font-weight: 600;
								color: #1E1E1E;
								line-height: 18px;
								@include max-screen(767px) {
									@include font-size(12);
								}
							}
						}	
					}
					&__boxDes {
						h2 {
							@include font-size(18);
							line-height: 1.55em;
							font-weight: 600;
							margin: 15px 0 0;
							@include max-screen(767px) {
								@include font-size(14);
								font-weight: 300;
								margin: 5px 0 0;
							}
						}
						p {
							@include font-size(14);
							line-height: 1.71em;
							margin: 10px 0 0;
							@include max-screen(767px) {
								display: none;
							}
						}
					}
				}
				&__boxInfo02 {
					width: 100%;
				}
			}
		}
	}
}