.column {
	.footer {
		@include max-screen(767px) {
			margin: 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/column/column_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/column/column_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__btn-loadmore {
		margin: 60px auto 0;
		@include max-screen(767px) {
			margin: 40px auto 0 !important;
		}
		a {
			position: relative;
			&:before {
				content: '';
				background: url('../img/common/ico_arrow_w_down.svg') no-repeat 0 0;
				background-size: 9px 6px;
				width: 9px;
				height: 6px;
				position: absolute;
				top: 50%;
				right: 15px;
				margin-top: -3px;
			}
		}
	}
	&__article {
		&__articleWrap {
			@include clearfix();
			margin: 30px -15px 0;
			@include max-screen(767px) {
				margin: 0 -10px 0;
			}
			&__item {
				padding: 0 15px;
				margin: 50px 0 0;
				display: none;
				@include max-screen(767px) {
					padding: 0 10px;
					margin: 30px 0 0 !important;
				}
				figure {
					position: relative;
					line-height: 0;
					a {
						img {
							width: 100%;
						}
					}
					.box-cate {
						position: absolute;
						top: 0;
						left: 0;
						.cate-label {
							@include font-size(13);
							line-height: 24px;
							text-align: center;
							float: left;
							color: #fff;
							padding: 0 11px;
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 27px;
							}
							@include max-screen(320px) {
								@include font-size(12);
							}
						}
					}	
				}
				&__boxInfo {
					h3 {
						@include font-size(15);
						line-height: 1.46em;
						color: #1E1E1E;
						text-align: left;
						font-weight: 600;
						letter-spacing: 0.04em;
						margin: 6px 0 0;
					}
					.box-content {
						p {
							@include font-size(15);
							line-height: 1.73em;
							text-align: justify;
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 1.71em;
							}
						}
					}
				}
				&:first-child {
					h3 {
						@include font-size(22);
						line-height: 1.4em;
						margin: 25px 0 0;
						@include min-screen(768px) {
							@include font-size(17);
						}
						@include min-screen(992px) {
							@include font-size(22);
						}
						@include max-screen(767px) {
							@include font-size(17);
							margin: 20px 0 0;
						}
					}
					.box-content {
						margin: 15px 0 0;
						@include max-screen(767px) {
							margin: 10px 0 0;
						}
					}
				}
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					margin: 62px 0 0;
				}
			}
		}
	}
}