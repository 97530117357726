.company {
	.footer {
		margin: 165px 0 0;
		@include max-screen(767px) {
			margin: 60px 0 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/company/company_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/company/company_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__btn {
		margin: 30px auto 0;
		@include max-screen(767px) {
			margin: 20px auto 0;
		}
		a {
			position: relative;
			@include max-screen(767px) {
				line-height: 44px;
				@include font-size(13);
			}
			&:before {
				content: '';
				background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
				background-size: 4px 8px;
				width: 4px;
				height: 8px;
				position: absolute;
				top: 50%;
				right: 35px;
				margin-top: -4px;
			}
		}
	}
	&__h3 {
		text-align: center;
		@include font-size(22);
		line-height: 1.45em;
		background: rgba(21,148,176,0.1);
		font-weight: 600;
		letter-spacing: 0.04em;
		padding: 15px 0;
		@include max-screen(767px) {
			@include font-size(16);
			padding: 13px 0;
			line-height: 1.5em;
			margin: 0 -15px;
		}
		span {
			color: #1594B0;
			&.l-txt {
				@include font-size(25);
				@include max-screen(767px) {
					@include font-size(20);
				}
			}
		}
		em {
			@include font-size(25);
			font-style: normal;
			@include max-screen(767px) {
				@include font-size(20);
			}
		}
	}
	&__flow {
		padding: 0 20px;
		margin: 10px 0 0;
		@include max-screen(767px) {
			padding: 0;
			margin: 0;
		}
		&__item {
			position: relative;
			border-bottom: 1px solid #E6E6E6;
			padding: 21px 0;
			&:last-child {
				border-bottom: transparent;
			}
			&__num {
				position: absolute;
				top: 20px;
				left: 10px;
				@include max-screen(767px) {
					left: 0;
					top: 15px;
				}
				span {
					color: #fff;
					text-align: center;
					width: 28px;
					height: 28px;
					@include font-size(20);
					font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
					font-weight: 600;
					background: #FA86AF;
					@include border-radius(100%);
					display: block;
					line-height: 28px;
					letter-spacing: 0;
					@include max-screen(767px) {
						@include font-size(16);
						width: 29px;
						height: 29px;
						line-height: 29px;
					}
				}
			}
			&__num02 {
				top: 18px;
			}
			&__content {
				padding: 0 0 0 50px;
				display: table;
				width: 100%;
				@include max-screen(767px) {
					padding: 0 0 0 40px;
				}
				.ttl {
					@include font-size(22);
					font-weight: 600;
					color: #1594B0;
					line-height: 1.1em;
					display: table-cell;
					vertical-align: top;
					width: 80px;
					padding: 0 10px 0 0;
					@include max-screen(767px) {
						@include font-size(17);
						display: block;
						width: 100%;
					}
				}
				.text {
					@include font-size(18);
					font-weight: 600;
					color: #1E1E1E;
					line-height: 1.3em;
					display: table-cell;
					vertical-align: top;
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 1.7em;
						display: block;
						margin: 7px 0 0;
					}
				}
			}
			&__content02 {
				padding: 0 0 0 50px;
				display: block;
				width: 100%;
				@include max-screen(767px) {
					padding: 0 0 0 40px;
				}
				.ttl {
					@include font-size(22);
					font-weight: 600;
					color: #1594B0;
					line-height: 1.1em;
					display: block;
					width: 100%;
					padding: 0 10px 0 0;
					@include max-screen(767px) {
						@include font-size(17);
						line-height: 1.4em;
					}
				}
				.text {
					@include font-size(15);
					color: #1E1E1E;
					line-height: 1.6em;
					display: block;
					font-weight: 300;
					margin: 10px 0 0;
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 1.7em;
					}
					span {
						font-weight: 600;
					}
				}
			}
		}
		&__item02 {
			&:last-child {
				@include max-screen(767px) {
					border-bottom: 1px solid #E6E6E6;
				}
			}
		}
	}
	&__tbl {
		padding: 0 20px;
		margin: 40px 0 0;
		@include max-screen(767px) {
			padding: 0;
			margin: 20px 0 0;
		}
		table {
			tr {
				border-bottom: 1px solid #E6E6E6;
				&:first-child {
					border-top: 1px solid #E6E6E6;
				}
				th {
					@include font-size(14);
					font-weight: 600;
					text-align: left;
					vertical-align: top;
					width: 13.5%;
					background: #F7F7F7;
					padding: 16px 10px;
					@include max-screen(767px) {
						width: 28%;
					}
				}
				td {
					padding: 13px 30px 15px;
					@include max-screen(767px) {
						padding: 15px 0 15px 15px;
						vertical-align: middle;
					}
					p {
						@include font-size(15);
						line-height: 1.86em;
						@include max-screen(767px) {
							@include font-size(14);
							line-height: 1.7em;
						}
					}
					.list-img {
						margin: 15px 0 0;
						li {
							float: left;
							margin: 6px 6px 0 0;
							@include max-screen(767px) {
								float: none;
								margin: 0;
							}
							&:last-child {
								margin: 6px 0 0;
								@include max-screen(767px) {
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	&__listBtn {
		margin: 25px 0 0;
		@include max-screen(767px) {
			margin: 30px 0 0;
		}
		ul {
			@include clearfix();
			margin: 0 -10px;
			@include max-screen(767px) {
				margin: 0 -15px;
			}
			li {
				float: left;
				width: 33.33%;
				padding: 0 10px;
				margin: 15px 0 0;
				@include max-screen(767px) {
					padding: 0;
					margin: 0;
					border-right: 1px solid rgba(149,152,154,0.2);
					border-top: 1px solid rgba(149,152,154,0.2);
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {
						border-top: none;
					}
					&:nth-child(3),
					&:nth-child(6) {
						border-right: none;
					}
				}
				a {
					display: block;
					background: #F7F7F7;
					@include font-size(16);
					font-weight: 600;
					line-height: 60px;
					position: relative;
					text-align: center;
					@include max-screen(767px) {
						@include font-size(13);
						line-height: 50px;
					}
					&:before {
						content: '';
						background: url('../img/common/ico_arrow_b_down.svg') no-repeat 0 0;
						background-size: 8px 4px;
						width: 8px;
						height: 4px;
						position: absolute;
						top: 50%;
						right: 10px;
						margin-top: -2px;
					}
				}
			}
		}
	}
	&__lastBtn {
		margin: 95px 0 0;
		@include max-screen(767px) {
			margin: 30px 0 0;
		}
		ul {
			@include clearfix();
			margin: 0 -10px;
			li {
				float: left;
				padding: 0 10px;
				@include max-screen(767px) {
					margin: 20px 0 0;
				}
				a {
					@include font-size(14);
					line-height: 160px;
					display: block;	
					background: #E6E6E6;
					text-align: center;
					@include max-screen(767px) {
						line-height: 100px;
					}
				}
				&:first-child {
					a {
						@include max-screen(767px) {
							line-height: 132px;
						}
					}
				}
			}
		}
	}
	&__philosophy {
		margin: 75px 0 0;
		@include max-screen(767px) {
			margin: 50px 0 0;
		}
		&__info {
			p {
				text-align: center;
				@include font-size(15);
				line-height: 1.86em;
				margin: 15px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.7em;
					text-align: left;
				}
			}
		}
		&__box {
			margin: 45px 0 0;
			@include max-screen(767px) {
				margin: 30px 0 0;
			}
		}
	}
	&__outline {
		margin: 80px 0 0;
		@include max-screen(767px) {
			margin: 35px 0 0;
		}
	}
	&__message {
		margin: 100px 0 0;
		@include max-screen(767px) {
			margin: 60px 0 0;
		}
		&__wrap {
			padding: 0 10px;
			margin: 40px 0 0;
			@include clearfix();
			@include max-screen(767px) {
				margin: 20px 0 0;
			}
			&__boxText {
				float: left;
				width: calc(100% - 206px);
				padding: 0 15px 0 10px;
				@include max-screen(767px) {
					width: 100%;
					float: none;
					padding: 0;
				}
				p {
					@include font-size(15);
					line-height: 1.86em;
					text-align: left;
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 1.7em;
					}
				}
			}
			&__boxImg {
				float: left;
				padding: 0 10px 0 15px;
				width: 206px;
				@include max-screen(767px) {
					width: 160px;
					float: none;
					padding: 0;
					margin: 35px auto 0;
					text-align: center;
				}
				figure {
					img {
						width: 100%;
						@include max-screen(767px) {
							max-width: 100%;
						}
					}
					figcaption {
						text-align: center;
						margin: 10px 0 0;
						@include max-screen(767px) {
							margin: 5px 0 0;
						}
						small {
							display: block;
							@include font-size(14);
							@include max-screen(767px) {
								display: inline-block;
								padding: 0 10px 0 0;
							}
						}
						span {
							@include font-size(18);
							font-weight: 600;
							@include max-screen(767px) {
								@include font-size(17);
							}
						}
					}
				}
			}
		}
	}
	&__strong {
		margin: 90px 0 0;
		@include max-screen(767px) {
			margin: 55px 0 0;
		}
		&__boxInfo {
			margin: 50px 0 0;
			padding: 0 20px;
			@include max-screen(767px) {
				padding: 0;
				margin: 25px 0 0;
			}
			h4 {
				@include font-size(20);
				line-height: 1.3em;
				font-weight: 600;
				letter-spacing: 0.04em;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.52em;
				}
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				margin: 20px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.7em;
					margin: 15px 0 0;
				}
			}
		}
		&__boxList {
			margin: 55px 0 0;
			@include max-screen(767px) {
				margin: 50px 0 0;
			}
		}
		&__boxActivity {
			margin: 60px 0 0;
			@include max-screen(767px) {
				margin: 30px 0 0;
			}
			&__wrap {
				margin: -10px 0 0;
				@include max-screen(767px) {
					margin: -20px 0 0;
				}
			}
			&__wrapItem {
				margin: 40px 0 0;
				@include clearfix();
				@include max-screen(767px) {
					margin: 50px 0 0;
				}
				&__boxText {
					padding: 0 20px;
					float: left;
					width: 56%;
					@include max-screen(767px) {
						padding: 0;
						float: none;
						width: 100%;
					}
					h4 {
						font-weight: 600;
						@include font-size(19);
						line-height: 1.4em;
						position: relative;
						padding: 0 0 0 15px;
						@include max-screen(767px) {
							@include font-size(16);
							line-height: 1.7em;
						}
						&:before {
							content: '';
							width: 4px;
							height: 26px;
							background: #FA9F0C;
							position: absolute;
							top: 0;
							left: 0;
						}
					}
					p {
						@include font-size(15);
						line-height: 1.86em;
						margin: 20px 0 0;
						@include max-screen(767px) {
							@include font-size(14);
							line-height: 1.7em;
							margin: 10px 0 0;
						}
					}
				}
				&__boxText02 {
					float: right;
					@include max-screen(767px) {
						float: none;
					}
				}
				&__boxImg {
					padding: 0 20px;
					float: right;
					width: 44%;
					@include max-screen(767px) {
						width: 100%;
						float: none;
						padding: 0;
						margin: 15px 0 0;
					}
					figure {
						line-height: 0;
						img {
							width: 100%;
						}
					}
				}
			}
		}
	}
	&__profile {
		margin: 90px 0 0;
		@include max-screen(767px) {
			margin: 60px 0 0;
		}
	}
	&__access {
		margin: 95px 0 0;
		@include max-screen(767px) {
			margin: 60px 0 0;
		}
		&__boxMap {
			.text-map {
				@include font-size(15);
				margin: 20px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					margin: 10px 0 0;
				}
			}
			&__wrap {
				@include clearfix();
				margin: 40px -10px 0;
				@include max-screen(767px) {
					margin: 20px -15px 0;
				}
			}
			&__map {
				float: left;
				width: 68%;
				height: 380px;
				padding: 0 10px;
				@include max-screen(767px) {
					float: none;
					width: 100%;
					height: 450px;
					padding: 0;
					margin: 50px 0 0;
				}
			}
			&__img {
				float: right;
				width: 32%;
				padding: 0 13px;
				@include max-screen(767px) {
					float: none;
					width: 100%;
					padding: 0 15px;
				}
				ul {
					li {
						line-height: 0;
						margin: 0 0 20px;
						@include max-screen(767px) {
							margin: 0;
						}
						img {
							width: 100%;
						}
						&:last-child,
						&:nth-child(2) {
							margin: 0;
						}
					}
				}
			}
		}
	}
}