.sitemap {
	.footer {
		margin: 95px 0 0;
		@include max-screen(767px) {
			margin: 40px 0 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/sitemap/sitemap_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/sitemap/sitemap_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__article {
		&__inner {
			max-width: 840px;
			margin: 30px auto 0;
			@include max-screen(767px) {
				margin: 20px 0 0;
				max-width: 100%;
			}
		}
		&__boxLink {
			&__item {
				margin: 15px 0 0;
				.link-ttl {
					a {
						@include font-size(15);
						color: #0A0A0A;
						background: rgba(21,148,176,0.1);
						line-height: 32px;
						font-weight: 600;
						padding: 0 25px;
						position: relative;
						display: block;
						@include max-screen(767px) {
							@include font-size(14);
						}
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 11px;
							left: 10px;
							@include max-screen(767px) {
								background-size: 4px 8px;
								width: 4px;
								height: 8px;
							}
						}
					}
				}
				&__link {
					@include clearfix();
					.link {
						@include clearfix();
						li {
							padding: 0 10px;
							margin: 12px 0;
							@include max-screen(767px) {
								margin: 5px 0;
							}
							a {
								@include font-size(15);
								color: #0A0A0A;
								position: relative;
								padding: 0 0 0 10px;
								line-height: 1.73em;
								display: inline-block;
								@include max-screen(767px) {
									@include font-size(14);
									line-height: 1.85em;
								}
								@include max-screen(320px) {
									@include font-size(12);
								}
								&:before {
									content: '';
									background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
									background-size: 6px 9px;
									width: 6px;
									height: 9px;
									position: absolute;
									top: 7px;
									left: 0;
									@include max-screen(767px) {
										background-size: 4px 8px;
										width: 4px;
										height: 8px;
									}
								}
							}
						}
					}
					&--01 {
						max-width: 700px;
						margin: 0 auto;
						padding: 15px 0;
						@include max-screen(767px) {
							max-width: 100%;
						}
						.link {
							width: 60%;
							float: left;
							@include max-screen(767px) {
								width: 50% !important;
							}
							&:last-child {
								width: 40%;
								li {
									width: 65%;
									&:nth-of-type(2n) {
										width: 35%;
									}
								}
							}
							li {
								width: 50%;
								float: left;
								@include max-screen(767px) {
									width: 100% !important;
									float: none !important;
								}
							}
						}
					}
					&--02 {
						max-width: 768px;
						margin: 10px auto 0;
						border-bottom: 1px solid #E6E6E6;
						padding: 15px 0;
						@include max-screen(767px) {
							max-width: 100%;
							margin: 0;
							padding: 30px 0;
						}
						&:last-child {
							border-bottom: none;
						}
						.link {
							@include max-screen(767px) {
								float: left;
								width: 50%;
							}
							li {
								width: 25%;
								float: left;
								@include max-screen(767px) {
									width: 100% !important;
								}
								&:nth-of-type(4n-1) {
									width: 30%;
								}
								&:nth-of-type(4n) {
									width: 20%;
								}
							}
						}
					}
					&--03 {
						max-width: 100%;
						padding: 0;
					}
					&--SP {
						@include max-screen(767px) {
							margin: -15px 0 0;
						}
					}
				}	
			}
		}
	}
}