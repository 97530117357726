.results {
	.subpage {
		&__category {
			@include max-screen(767px) {
				margin: 60px 0 0;
			}
		}
	}
	&__detail {
		&__inner {
			max-width: 840px;
			margin: 65px auto 0;
			@include max-screen(767px) {
				margin: 30px auto 0;
			}
		}
		&__boxInfo {
			&__ttl {
				border-bottom: 1px solid #1594B0;
				padding: 0 0 10px;
				margin: 40px 0 0;
				padding: 0 0 15px;
				@include max-screen(767px) {
					margin: 20px 0 0;
					border-bottom: none;
					padding: 0;
				}
			}
			.text-info {
				color: #585858;
				@include font-size(15);
				line-height: 1.6em;
				margin: 20px 0 0;
				@include max-screen(767px) {
					text-align: center;
					margin: 10px 0 0;
				}
			}
			.h2_cms {
				@include font-size(24);
				@include max-screen(767px) {
					@include font-size(19);
					line-height: 1.57em;
				}
			}
		}
		&__boxImg {
			position: relative;
			max-width: 480px;
			margin: 0 auto;
			@include max-screen(767px) {
				max-width: 100%;
			}
			figure {
				line-height: 0;
				img {
					width: 100%;
				}
			}
			.box-cate {
				position: absolute;
				top: 0;
				left: 0;
				.cate-label {
					@include font-size(14);
					line-height: 27px;
					text-align: center;
					float: left;
					color: #fff;
					padding: 0 13px;
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 27px;
					}
					@include max-screen(320px) {
						@include font-size(12);
					}
				}
					}
		}
		&__boxContent {
			margin: 25px 0 0;
			@include max-screen(767px) {
				border-top: 1px solid #E6E6E6;
				margin: 20px 0 0;
			}
			p {
				@include font-size(14);
				line-height: 2em;
				margin: 15px 0 0;
				img {
					margin: 25px 0 0;
					@include max-screen(767px) {
						margin: 0;
						width: 100%;
					}
					&.vt-img {
						max-height: 700px;
						@include max-screen(767px) {
							max-height: auto;
						}
					}
				}
				em {
					font-style: normal;
					@include font-size(15);
					margin: -20px 0 0;
					display: block;
					@include max-screen(767px) {
						margin: -15px 0 0;
					}
				}
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.71em;
				}
			}
			h2 {
				@include font-size(22);
				font-weight: 600;
				line-height: 1.45em;
				text-align: left;
				margin: 35px 0 0;
				color: #1E1E1E;
				@include max-screen(767px) {
					@include font-size(19);
					line-height: 1.57em;
					margin: 20px 0 0;
				}
			}
			h3 {
				@include font-size(17);
				color: #1E1E1E;
				line-height: 1.52em;
				font-weight: 600;
				border-bottom: 1px solid #1594B0;
				padding: 0 0 10px;
				margin: 30px 0 0;
				@include max-screen(767px) {
					margin: 20px 0 0;
				}
			}
			h4 {
				@include font-size(20);
				line-height: 1.4em;
				font-weight: 600;
				color: #1E1E1E;
				position: relative;
				padding: 0 0 0 20px;
				margin: 45px 0 0;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.3em;
					padding: 0 0 0 15px;
					margin: 40px 0 0;
				}
				&:before {
					content: '';
					width: 4px;
					height: 26px;
					background: #1594B0;
					position: absolute;
					top: 0;
					left: 0;
					@include max-screen(767px) {
						height: 100%;
					}
				}
			}
			h5 {
				@include font-size(18);
				color: #1594B0;
				line-height: 1.22em;
				font-weight: 600;
				margin: 40px 0 0;
				@include max-screen(767px) {
					@include font-size(15);
					line-height: 1.46em;
					margin: 30px 0 0;
				}
			}
		}
		&__boxListimg {
			.item-img {
				@include clearfix();
				margin: 20px -10px 0;
				@include max-screen(767px) {
					margin: 10px -5px 0;
				}
				li {
					float: left;
					width: 50%;
					padding: 10px;
					line-height: 0;
					@include max-screen(767px) {
						padding: 5px;
					}
					&.large {
						width: 100%;
						float: none;
					}
					img {
						width: 100%;
					}
				}
			}
		}
	}
}