@charset "UTF-8";

/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	line-height: 0;
}

.clearfix {
	display: inline-block;
}


/* exlude MacIE5 \*/

* html .clearfix {
	height: 1%
}

.clearfix {
	display: block;
}


/* end MacIE5 */


/*-----------------------------------------------------------
reset
------------------------------------------------------------*/

img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}

table img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}

p {
	margin: 0;
	padding: 0;
	letter-spacing: 0.02em;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

input,
textarea {
	margin: 0;
	font-size: 100%;
	resize: none;
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}

dl,
dt,
dd,
th,
td {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	/*font-size: 100%;*/
	font-weight: normal;
	margin: 0;
	padding: 0;
}

pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}

nav ul {
	list-style: none;
}


/*-----------------------------------------------------------
Link
------------------------------------------------------------*/

a {
	outline: none;
	transition: 0.3s;
}

a:link {
	color: #1E1E1E;
	text-decoration: none;
}

a:visited {
	color: #1E1E1E;
	text-decoration: none;
}

a:hover {
	color: #aaa;
	text-decoration: none;
	opacity: .8;
}

a:active {
	color: #1E1E1E;
	text-decoration: none;
}


/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/

.ffN {
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.ffM {
	font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
}


/* =WordPress Core
-------------------------------------------------------------- */

.alignnone {
	margin: 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 0 auto;
}

.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}

.alignleft {
	float: left;
}

a img.alignright {
	float: right;
}

a img.alignnone {
	margin: 0;
}

a img.alignleft {
	float: left;
	margin: 0;
}

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption {
	background: #fff;
	border: 1px solid #f0f0f0;
	max-width: 96%;
	/* Image does not overflow the content area */
	padding: 5px 3px 10px;
	text-align: center;
}

.wp-caption.alignnone {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
	margin: 5px 0 20px 20px;
}

.wp-caption img {
	border: 0 none;
	height: auto;
	margin: 0;
	max-width: 98.5%;
	padding: 0;
	width: auto;
}

.wp-caption p.wp-caption-text {
	font-size: 11px;
	line-height: 17px;
	margin: 0;
	padding: 0 4px 5px;
}

.contentcms img {
	max-width: 100%;
	height: auto;
	max-height: 100%;
	width: 100%;
}


/* Text meant only for screen readers. */

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}


/*-----------------------------------------------------------
PAGE BOOT
------------------------------------------------------------*/


/* MovableType */

div#pageBoot {
	margin: 35px 0 60px 0;
	text-align: center;
}

div#pageBoot a {
	color: #aaa;
	text-decoration: none !important;
}

span.current_page {
	color: #FFFFFF;
	padding: 5px;
	border: 1px solid #aaa;
	background-color: #aaa;
}

a.link_page {
	padding: 5px;
	border: 1px solid #aaa !important;
}

a.link_page:hover {
	padding: 5px;
	border: 1px solid #aaa;
	background-color: #aaa;
	color: #FFFFFF !important;
}


/* WordPress */

.wp-pagenavi {
	clear: both;
	text-align: center;
	font-family: Arial;
	font-size: 14px;
}

.wp-pagenavi span,
.wp-pagenavi a {
	line-height: 30px;
	display: inline-block;
	margin: 0 4px;
	color: #666;
	text-align: center;
}

.wp-pagenavi a,
.wp-pagenavi span.current {
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	width: 30px;
	height: 30px;
	background: #ededed;
}

.wp-pagenavi a:hover,
.wp-pagenavi span.current {
	background: #1489c3;
	color: #fff;
}

.wp-pagenavi .pages {
	margin-right: 15px;
}


/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/

body {
	width: 100%;
	color: #1E1E1E;
	margin: 0;
	padding: 0;
	line-height: 1.6;
	letter-spacing: 0;
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: 12px;
	height: 100%;
	word-break: break-word;
}




/*-----------------------------------------------------------
 Container/Maincontent
------------------------------------------------------------*/

.container {
	max-width: 1030px;
	margin: 0 auto;
}


/*-----------------------------------------------------------
common
------------------------------------------------------------*/

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.sp {

	@include min-screen(768px) {
		display: none!important;
	}
}

.pc {
	@include max-screen(767px) {
		display: none!important;
	}
}

img {
	max-width: 100%;
	height: auto;
}


.wp-pagenavi,
#textile .wp-pagenavi {
	text-align: right;
}

#wrap {
	margin-top: 74px;
	overflow: hidden;
	@include max-screen(767px) {
		margin-top: 54px;
	}
}
