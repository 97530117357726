.bl-hover {
	opacity: .75;
}
.footer-none {
	.footer {
		display: none !important;
	}
}
.header-none {
	.header {
		&__boxGnavi {
			display: none !important;
		}
	}
}
.contactThanks {
	.footer {
		margin: 125px 0 0;
		@include max-screen(767px) {
			margin: 45px 0 0;
		}
	}
}
.subpage_h2 {
	text-align: center;
	@include font-size(26);
	font-weight: 600;
	letter-spacing: 0.04em;
	line-height: 1.4em;
	@include max-screen(767px) {
		@include font-size(20);
		line-height: 1.3em;
	}
}
.subpage_h3 {
	@include font-size(25);
	line-height: 1.2em;
	font-weight: 600;
	text-align: center;
	border-bottom: 2px solid #1594B0;
	padding: 0 0 10px;
	@include max-screen(767px) {
		@include font-size(19);
	}
}
.h2_cms {
	@include font-size(22);
	font-weight: 600;
	line-height: 1.45em;
	text-align: left;
	margin: 35px 0 0;
	color: #1E1E1E;
	@include max-screen(767px) {
		@include font-size(19);
		line-height: 1.57em;
		margin: 20px 0 0;
	}
}
.h3_cms {
	@include font-size(17);
	color: #1E1E1E;
	line-height: 1.52em;
	font-weight: 600;
	border-bottom: 1px solid #1594B0;
	padding: 0 0 10px;
	margin: 30px 0 0;
	@include max-screen(767px) {
		margin: 20px 0 0;
	}
}
.h4_cms {
	@include font-size(20);
	line-height: 1.4em;
	font-weight: 600;
	color: #1E1E1E;
	position: relative;
	padding: 0 0 0 20px;
	margin: 45px 0 0;
	@include max-screen(767px) {
		@include font-size(17);
		line-height: 1.3em;
		padding: 0 0 0 15px;
		margin: 40px 0 0;
	}
	&:before {
		content: '';
		width: 4px;
		height: 26px;
		background: #1594B0;
		position: absolute;
		top: 0;
		left: 0;
		@include max-screen(767px) {
			height: 100%;
		}
	}
}
.h5_cms {
	@include font-size(18);
	color: #1594B0;
	line-height: 1.22em;
	font-weight: 600;
	margin: 40px 0 0;
	@include max-screen(767px) {
		@include font-size(15);
		line-height: 1.46em;
		margin: 30px 0 0;
	}
}
.btnEffect {
	max-width: 270px;
	width: 100%;
	@include max-screen(767px) {
		max-width: 240px;
		margin: 0 auto;
	}
	a {
		@include font-size(15);
		color: #fff;
		text-align: center;
		display: block;
		background: #585858;
		line-height: 50px;
		@include transition(all 0.4s);
		@include max-screen(767px) {
			line-height: 44px;
		}
		span {
			position: relative;
			padding: 0 12px 0 0;
			@include max-screen(767px) {
				display: block;
				@include font-size(13);
			}
			&:before {
				content: '';
				background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
				background-size: 6px 9px;
				width: 6px;
				height: 9px;
				top: 50%;
				right: 0;
				margin-top: -4.5px;
				position: absolute;
				@include max-screen(767px) {
					right: 10px;
				}
			}
		}
		&:hover {
			opacity: 1;
			background-image: -webkit-linear-gradient(#585858, #1C1C1C);
			background-image: -o-linear-gradient(#585858, #1C1C1C);
			background-image: linear-gradient(#585858, #1C1C1C);
		}	
	}
}
.subpage {
	.subpage-block {
		margin: 60px 0 0;
		@include max-screen(767px) {
			margin: 35px 0 0;
		}
	}
	&__mainvisual {
		height: 136px;
		@include max-screen(767px) {
			height: 103px;
			background-size: cover !important;
		}
	}
	&__breadcrum {
		padding: 15px 0 0;
		@include max-screen(767px) {
			padding: 10px 0 0;
		}
		.breadcrum {
			@include clearfix();
			@include max-screen(767px) {
				margin: 0 -10px;
			}
			li {
				@include font-size(11);
				color: #585858;
				float: left;
				position: relative;
				padding: 0 10px;
				&:before {
					content: '';
					background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
					background-size: 6px 9px;
					width: 6px;
					height: 9px;
					position: absolute;
					top: 50%;
					right: -5px;
					margin-top: -4.5px;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
				a {
					@include font-size(11);
					color: #585858;
					letter-spacing: 0.02em;
					img {
						vertical-align: baseline;
					}
				}
			}
		}
	}
	&__title {
		@include font-size(30);
		letter-spacing: 0.04em;
		color: #1E1E1E;
		font-weight: 600;
		text-align: center;
		@include max-screen(767px) {
			@include font-size(21);
		}
	}
	&__pageDetail {
		@include clearfix();
		border-top: 1px solid #E6E6E6;
		margin: 70px 0 0;
		padding: 40px 0 0;
		position: relative;
		@include max-screen(767px) {
			padding: 0;
			border-top: none;
			margin: 40px -15px 0;
		}
		&__wrap {
			@include max-screen(767px) {
				background: #F7F7F7;
			}
		}
		&__wrapItem {
			@include clearfix();
			@include max-screen(767px) {
				margin: 0 15px;
			}
		}
		&__item {
			width: 32%;
			@include max-screen(767px) {
				width: 100%;
				float: none !important;
				padding: 16px 25px !important;
			}
			a {
				@include font-size(15);
				color: #1594B0;
				font-weight: 600;
				@include max-screen(767px) {
					@include font-size(12);
				}
			}
			.ttl {
				text-align: left;
				@include font-size(15);
				font-weight: 600;
				line-height: 1.46em;
				position: relative;
				margin: 5px 0 0;
			}
			&--back {
				float: left;
				text-align: left;
				padding: 0 0 0 20px;
				@include max-screen(767px) {
					border-bottom: 1px solid #E6E6E6;
				}
				.ttl {
					&:before {
						content: '';
						background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
						background-size: 6px 9px;
						width: 6px;
						height: 9px;
						position: absolute;
						top: 50%;
						left: -20px;
						margin-top: -4.5px;
						@include rotate(-180);
						@include max-screen(767px) {
							left: -25px;
						}
					}
				}
			}
			&--next {
				float: right;
				text-align: right;
				padding: 0 20px 0 0;
				.ttl {
					text-align: left;
					&:before {
						content: '';
						background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
						background-size: 6px 9px;
						width: 6px;
						height: 9px;
						position: absolute;
						top: 50%;
						right: -20px;
						margin-top: -4.5px;
						@include max-screen(767px) {
							right: -25px;
						}
					}
				}
			}
		}
		&__btnBack {
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 240px;
			width: 100%;
			margin-left: -120px;
			margin-top: 10px;
			@include max-screen(767px) {
				position: relative;
				margin: 20px auto 0;
				top: 0;
				left: 0;
			}
			&:before {
				content: '';
				background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
				background-size: 6px 9px;
				width: 6px;
				height: 9px;
				position: absolute;
				top: 50%;
				left: 10px;
				margin-top: -4.5px;
				@include rotate(-180);
			}
			a {
				line-height: 44px;
			}
		}
	}
	&__pageDetail02 {
		@include clearfix();
		border-top: 1px solid #E6E6E6;
		margin: 60px 0 0;
		padding: 60px 0 0;
		position: relative;
		@include max-screen(767px) {
			padding: 0;
			border-top: none;
			margin: 60px 0 0;
		}
		&__wrap {
			max-width: 510px;
			margin: 0 auto;
			@include max-screen(767px) {
				max-width: 100%;
			}
		}
		&__wrapItem {
			@include clearfix();
			@include max-screen(767px) {
				margin: 0 -5px;
			}
		}
		&__item {
			width: 50%;
			@include max-screen(767px) {
				padding: 0 5px !important;
			}
			a {
				@include font-size(15);
				color: #1594B0;
				font-weight: 600;
				position: relative;
				margin: 10px 0 0;
				display: inline-block;
				@include max-screen(767px) {
					@include font-size(11);
				}
			}
			figure {
				line-height: 0;
				img {
					width: 100%;
				}
			}
			.ttl {
				text-align: left;
				@include font-size(15);
				font-weight: 600;
				line-height: 1.46em;
				position: relative;
				margin: 5px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.35em;
				}
			}
			.boxinfo {
				@include max-screen(767px) {
					background: #F7F7F7;
					padding: 0 0 20px;
				}
			}
			&--back {
				float: left;
				text-align: left;
				padding: 0 35px 0 0;
				.ttl {
					@include max-screen(767px) {
						padding: 0 10px 0 30px;
						position: relative;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							left: 15px;
							margin-top: -4.5px;
							@include rotate(-180);
						}
					}
				}
				a {
					padding: 0 0 0 15px;
					@include max-screen(767px) {
						padding: 0 0 0 30px;
					}
					&:before {
						content: '';
						background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
						background-size: 6px 9px;
						width: 6px;
						height: 9px;
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -4.5px;
						@include rotate(-180);
						@include max-screen(767px) {
							display: none;
						}
					}
				}
			}
			&--next {
				float: right;
				text-align: right;
				padding: 0 0 0 35px;
				.ttl {
					text-align: left;
					@include max-screen(767px) {
						padding: 0 30px 0 10px;
						position: relative;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							right: 15px;
							margin-top: -4.5px;
						}
					}
				}
				a {
					text-align: left;
					padding: 0 15px 0 0;
					@include max-screen(767px) {
						padding: 0 30px 0 0;
					}
					&:before {
						content: '';
						background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
						background-size: 6px 9px;
						width: 6px;
						height: 9px;
						position: absolute;
						top: 50%;
						right: 0;
						margin-top: -4.5px;
						@include max-screen(767px) {
							display: none;
						}
					}
				}
			}
		}
		&__btnBack {
			margin: 40px auto 0;
			position: relative;
			&:before {
				content: '';
				background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
				background-size: 6px 9px;
				width: 6px;
				height: 9px;
				position: absolute;
				top: 50%;
				left: 10px;
				margin-top: -4.5px;
				@include rotate(-180);
			}
			a {
				line-height: 44px;
			}
		}
	}
	&__pageNav {
		margin: 75px 0 0;
		@include max-screen(767px) {
			margin: 45px 0 0;
		}
		&__wrap {
			text-align: center;
			a,span {
				display: inline-block;
				width: 30px;
				height: 30px;
				line-height: 30px;
				background: #1594B0;
				vertical-align: middle;
				padding: 0 8px;
				margin: 0 4px;
				position: relative;
			}
			span.pages {
				width: auto;
				border: 1px solid #C0C0C0;
				color: #C0C0C0;
				@include font-size(14);
				background: #fff;
				@include max-screen(767px) {
					color: #828282;
				}
			}
			a.last,
			a.first {
				background: #585858;
				text-indent: -9999px;
			}
			a.nextpostslink {
				text-indent: -9999px;
				&:before {
					content: '';
					background: url('../img/common/ico_arrow_next.svg') no-repeat 0 0;
					background-size: 8px 13px;
					width: 8px;
					height: 13px;
					position: absolute;
					top: 8px;
					left: 11px;
				}
			}
			a.previouspostslink {
				text-indent: -9999px;
				&:before {
					content: '';
					background: url('../img/common/ico_arrow_next.svg') no-repeat 0 0;
					background-size: 8px 13px;
					width: 8px;
					height: 13px;
					position: absolute;
					top: 8px;
					left: 10px;
					@include rotate(-180);
				}
			}
			a.last {
				&:before {
					content: '';
					background: url('../img/common/ico_arrow_last.svg') no-repeat 0 0;
					background-size: 11px 13px;
					width: 11px;
					height: 13px;
					position: absolute;
					top: 9px;
					left: 11px;
				}
			}
			a.first {
				&:before {
					content: '';
					background: url('../img/common/ico_arrow_last.svg') no-repeat 0 0;
					background-size: 11px 13px;
					width: 11px;
					height: 13px;
					position: absolute;
					top: 9px;
					left: 9px;
					@include rotate(-180);
				}
			}
		}
	}
	&__category {
		background: #F7F7F7;
		display: table;
		width: 100%;
		margin: 95px 0 0;
		@include max-screen(767px) {
			margin: 40px 0 0;
			display: block;
			background: none;
		}
		&__inner {
			max-width: 905px;
			padding: 25px 30px;
			margin: 0 auto;
			@include max-screen(767px) {
				padding: 0;
			}
		}
		&__ttl {
			font-weight: 600;
			@include font-size(15);
			display: table-cell;
			vertical-align: middle;
			text-align: left;
			width: 110px;
			@include max-screen(767px) {
				display: block;
				text-align: center;
				width: 100%;
				@include font-size(17);
			}
		}
		&__list {
			display: table-cell;
			vertical-align: middle;
			@include max-screen(767px) {
				display: block;
				background: #f7f7f7;
				@include clearfix();
				margin: 15px 0 0;
			}
			li {
				float: left;
				padding: 0 15px;
				@include max-screen(767px) {
					padding: 0;
					width: 50%;
					border-right: 1px solid #E6E6E6;
					border-bottom: 1px solid #E6E6E6;
					&:nth-of-type(2n) {
						border-right: none;
					}
					&:last-child {
						border-bottom: none;
					}
				}
				a {
					@include font-size(14);
					color: #0A0A0A;
					line-height: 1.85em;
					@include max-screen(767px) {
						padding: 12px 15px;
						display: block;
						position: relative;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							right: 10px;
							margin-top: -4.5px;
						}
					}
				}
			}
		}
		&__list02 {
			li {
				@include max-screen(767px) {
					&:last-child,
					&:nth-last-child(2) {
						border-bottom: none;
					}
				}
			}
		}
	}
	&__archive {
		background: #E6E6E6;
		display: table;
		width: 100%;
		@include max-screen(767px) {
			display: block;
			background: none;
			margin: 20px 0 0;
		}
		&__inner {
			max-width: 905px;
			padding: 20px 30px 30px;
			margin: 0 auto;
			@include max-screen(767px) {
				padding: 0;
			}
		}
		&__ttl {
			font-weight: 600;
			@include font-size(15);
			display: table-cell;
			vertical-align: middle;
			text-align: left;
			width: 110px;
			@include max-screen(767px) {
				display: block;
				text-align: center;
				width: 100%;
				@include font-size(17);
			}
		}
		&__list {
			display: table-cell;
			vertical-align: middle;
			@include max-screen(767px) {
				display: block;
				background: #f7f7f7;
				@include clearfix();
				margin: 15px 0 0;
				display: none;
			}
			li {
				float: left;
				padding: 0 15px;
				@include max-screen(767px) {
					padding: 0;
					width: 50%;
					border-right: 1px solid #E6E6E6;
					border-bottom: 1px solid #E6E6E6;
					&:nth-of-type(2n) {
						border-right: none;
					}
				}
				a {
					@include font-size(14);
					color: #0A0A0A;
					line-height: 1.85em;
					@include max-screen(767px) {
						padding: 12px 15px;
						display: block;
						position: relative;
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							right: 10px;
							margin-top: -4.5px;
						}
					}
				}
			}
		}
		&__listSP {
			margin: 15px 0 0;
			display: none;
			@include max-screen(767px) {
				display: block;
			}
			dl {
				dt {
					a {
						padding: 12px 15px;
						position: relative;
						@include max-screen(767px) {
							padding: 15px;
						}
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							position: absolute;
							top: 50%;
							right: 20px;
							margin-top: -4.5px;
							@include rotate(90);
							@include transition(0.4s);
						}
						&:after {
							content: '';
							background: #E6E6E6;
							position: absolute;
							top: 0;
							right: 45px;
							width: 1px;
							height: 100%;
						}
						&.active {
							&:before {
								@include rotate(270);
							}
						}
					}
				}
				dd {
					position: relative;
				}
				ul {
					background: #F7F7F7 none repeat scroll 0 0;
			        color: #0A0A0A;
			        display: none;
			        left: 0px;
			        position: absolute;
			        top: 0px;
			        width: 100%;
			        list-style: none;
			        z-index: 9997;
				}
				a {
					@include font-size(14);
					color: #0A0A0A;
					background: #F7F7F7;
					display: block;
					font-weight: 300;
					padding: 7px 15px;
				}
			}
		}
	}
	&__boxContact {
		margin: 80px 0 0;
		@include max-screen(767px) {
			margin: 55px 0 0;
		}
		&__step2 {
			margin: 0;
			@include max-screen(767px) {
				margin: 10px 0 0;
			}
		}
		&__ttl {
			h3 {
				text-align: center;
				@include font-size(24);
				line-height: 90px;
				background: #FA9F0C;
				color: #fff;
				font-weight: 600;
				letter-spacing: 0.04em;
				@include max-screen(767px) {
					@include font-size(20);
					line-height: 50px;
				}
			}
		}
	}
	&__boxContactThanks {
		margin: 50px 0 0;
		@include max-screen(767px) {
			margin: 25px 0 0;
		}
		&__inner {
			max-width: 800px;
			margin: 0 auto;
			.step-contact {
				line-height: 0;
				text-align: center;
				@include max-screen(767px) {
					
				}
				img {
					width: 100%;
				}
			}
			h2 {
				@include font-size(20);
				line-height: 1.3em;
				font-weight: 600;
				margin: 40px 0 0;
				@include max-screen(767px) {
					@include font-size(16);
					margin: 25px 0 0;
				}
			}
			.txt {
				@include font-size(15);
				line-height: 1.86em;
				margin: 20px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.85em;
					margin: 15px 0 0;
				}
			}
		}
		&__btn {
			margin: 35px auto 0;
			@include max-screen(767px) {
				margin: 30px auto 0;
			}
			a {
				@include max-screen(767px) {
					line-height: 44px;
				}
				span {
					padding: 0 0 0 15px;
					@include max-screen(767px) {
						display: inline-block;
					}
					&:before {
						content: '';
						left: 0;
						right: inherit;
						@include rotate(-180);
					}
				}
			}
		}
	}
	&__boxForm {
		margin: 50px 0 0;
		@include max-screen(767px) {
			margin: 20px -15px 0;
		}
		.copy-right {
			text-align: center;
			@include font-size(10);
			color: #585858;
			margin: 75px 0 80px;
			@include max-screen(767px) {
				background: #585858;
				color: #fff;
				line-height: 32px;
				margin: 30px -15px 0;
			}
		}
		form {
			max-width: 800px;
			margin: 0 auto;
			@include max-screen(767px) {
				max-width: 100%;
				width: 100%;
			}
			.step-contact {
				line-height: 0;
				text-align: center;
				@include max-screen(767px) {
					padding: 0 15px;
				}
				img {
					width: 100%;
				}
			}
			.text-required {
				@include font-size(14);
				line-height: 1.7em;
				text-align: center;
				margin: 40px 0 0;
				@include max-screen(767px) {
					display: none;
					padding: 0 40px;
					margin: 20px 0 0;
				}
				span {
					color: #EB3953;
				}
			}
			.text-required02 {
				@include max-screen(767px) {
					display: block;
				}
			}
			.backButton {
				text-align: right;
				margin: 15px 0 0;
				a {
					@include font-size(12);
					line-height: 2em;
				}
			}
		}
		&__step2 {
			@include max-screen(767px) {
				margin: 20px 0 0;
				form {
					.step-contact {
						@include max-screen(767px) {
							padding: 0;
						}
					}
				}
			}
		}
		&__tblContact {
			border: 1px solid #E6E6E6;
			margin: 35px 0 0;
			@include max-screen(767px) {
				border: none;
				margin: 30px 0 0;
			}
			tr {
				border-bottom: 1px solid #E6E6E6;
				@include max-screen(767px) {
					border-bottom: none;
				}
				th {
					background: #F7F7F7;
					@include font-size(15);
					font-weight: 600;
					line-height: 1.73em;
					padding: 20px 20px 20px 80px;
					vertical-align: top;
					text-align: left;
					position: relative;
					width: 37.5%;
					@include max-screen(767px) {
						width: 100%;
						float: left;
						display: block;
						padding: 12px 15px 13px 70px;
					}
					em {
						@include font-size(14);
						font-style: normal;
						position: absolute;
						top: 20px;
						left: 20px;
						color: #fff;
						text-align: center;
						line-height: 22px;
						padding: 0 8px;
						font-weight: 300;
						@include max-screen(767px) {
							top: 13px;
							left: 15px;
						}
						&.required {
							background: #EB3953;
						}
						&.random {
							background: #585858;
						}
					}
				}
				td {
					padding: 10px 15px;
					@include max-screen(767px) {
						width: 100%;
						float: left;
						display: block;
						padding: 15px 15px 25px;
					}
					.sm-text {
						@include font-size(12);
						line-height: 1.5em;
						color: #585858;
						text-align: left;
						margin: 10px 0 0;
					}
					.sm-text2 {
						line-height: 2em;
					}
					span.ico-add {
						@include font-size(14);
						padding: 0 20px 0 10px;
						@include max-screen(767px) {
							padding: 0 10px;
						}
					}
					.text-age {
						@include font-size(14);
						padding: 0 0 0 10px;
					}
					.input {
						margin: 10px 0 0;
					}
					.form-control {
						width: 85%;
						height: 40px;
						border: 1px solid #E6E6E6;
						padding: 0 10px;
						@include font-size(14);
						color: #1E1E1E;
						line-height: 40px;
						outline: 0;
						letter-spacing: 0.04em;
						box-shadow: none;
						-webkit-appearance: none;
						@include border-radius(0);
						@include max-screen(767px) {
							width: 100%;
							height: 50px;
							line-height: 50px;
							padding: 0 20px;
						}
						&.size_m {
							width: 73%;
							@include max-screen(767px) {
								width: 100%;
							}
						}
						&.size_xm {
							width: 52%;
							@include max-screen(767px) {
								width: 55%;
							}
						}
						&.size_s {
							width: 140px;
						}
						&.size_xs {
							width: 215px;
							@include max-screen(767px) {
								width: 140px;
							}
						}
						&.w100 {
							width: 100%;
						}
						&.form-area {
							height: 220px;
							resize: none;
							width: 100%;
							line-height: 1.4em;
							padding: 10px;
							@include max-screen(767px) {
								height: 210px;
								padding: 10px 20px;
							}
						}
						&.form-area2 {
							height: 280px;
							@include max-screen(767px) {
								height: 210px;
							}
						}
						&.form-date {
							background: url('../img/common/ico_datetime.svg') no-repeat center right 10px;
							background-size: 20px 17px;
							float: left;
						}
						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							color: #BABABA;
						}
						&::-moz-placeholder { /* Firefox 19+ */
							color: #BABABA;
						}
						&:-ms-input-placeholder { /* IE 10+ */
							color: #BABABA;
						}
						&:-moz-placeholder { /* Firefox 18- */
							color: #BABABA;
						}
					}
					.model-time-ttl {
						@include font-size(14);
						letter-spacing: 0.05em;
						margin: 0 0 10px;
					}
					.box-time {
						@include clearfix();
						margin: 0 0 20px;
						@include max-screen(767px) {
							margin: 0 0 15px;
						}
					}
					.model-selectbox {
						float: left;
						@include max-screen(767px) {
							width: 45%;
							padding: 0 0 0 10px;
						}
						span {
							@include font-size(14);
							line-height: 1.85em;
							color: #1E1E1E;
							padding: 0 15px 0 10px;
							@include max-screen(767px) {
								display: none;
							}
						}
						.selectbox {
							position: relative;
							width: 154px;
							display: inline-block;
							@include max-screen(767px) {
								width: 100%;
							}
							&:before {
								content: '';
								background: #E6E6E6;
								width: 18px;
								height: 100%;
								position: absolute;
								top: 0;
								right: 0;
								z-index: -1;
								@include max-screen(767px) {
									width: 50px;
								}
							}
							.select-time {
								width: 100%;
								outline: 0;
								-webkit-appearance: none;
								height: 40px;
								border: 1px solid #E6E6E6;
								background: #fff;
								@include border-radius(0);
								color: #1E1E1E;
								padding: 0 10px;
								background: url('../img/common/ico_arrow_b_down.svg') no-repeat center right 4px;
								background-size: 6px 9px;
								@include max-screen(767px) {
									background: url('../img/common/ico_arrow_b_down.svg') no-repeat center right 20px;
									background-size: 6px 9px;
									height: 50px;
								}
							}
						}
					}
					.purpose-selectbox {
						width: 73%;
						.selectbox {
							width: 100%;
							width: 100%;
							outline: 0;
							-webkit-appearance: none;
							height: 40px;
							border: 1px solid #E6E6E6;
							background: #fff;
							@include border-radius(0);
							color: #585858;
							padding: 0 10px;
							@include font-size(14);
							background: url('../img/common/ico_arrow_select.svg') no-repeat center right 15px;
							background-size: 10px 14px;
							@include max-screen(767px) {
								background: url('../img/common/ico_arrow_select.svg') no-repeat center right 20px;
								background-size: 10px 14px;
								height: 50px;
							}
						}
					}	
					.checbox-box {
						p {
							display: block;
							position: relative;
							margin: 0 0 10px;
							&:last-child  {
								margin: 0;
							}
						}
						.form-checkbox {
							display: none;
							position: absolute;
							top: -999999px;
						}
						label {
							@include font-size(14);
							line-height: 1.85em;
							letter-spacing: 0.04em;
							position: relative;
							padding: 0 0 0 35px;
							color: #1E1E1E;
							&:before {
								content: '';
								background: transparent;
								width: 18px;
								height: 18px;
								border: 1px solid #E6E6E6;
								position: absolute;
								top: -2px;
								left: 0;
							}
							&:after {
								content: '';
								background: url('../img/common/ico_checked.svg') no-repeat 0 0;
								background-size: 11px 7px;
								width: 11px;
								height: 7px;
								position: absolute;
								top: 4px;
								left: 4px;
								display: none;
							}
						}
						input:checked+label {
							&:before {
								background: #1594B0;
								border: 1px solid transparent;
							}
							&:after {
								display: block;
							}
						}
					}
				}
			}
			&__step2 {
				border: none;
				tr {
					@include max-screen(767px) {
						border-bottom: 1px solid #e6e6e6;
					}
					th {
						padding: 17px 20px;
						background: none;
						width: 25.5%;
						@include max-screen(767px) {
							width: 100%;
							float: left;
							display: block;
							padding: 20px 0 0;
						}
					}
					td {
						@include font-size(14);
						padding: 17px 20px;
						@include max-screen(767px) {
							padding: 5px 0 10px;
						}
					}
				}
			}
		}
		&__textPrivacy {
			margin: 15px 0 0;
			@include max-screen(767px) {
				padding: 15px 15px 0;
				border-top: 1px solid #E6E6E6;
				margin: -5px 0 0;
			}
			p {
				@include font-size(12);
				line-height: 2em;
				color: #1E1E1E;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.7em;
				}
				a {
					text-decoration: underline;
					color: #1594B0;
				}
			}
		}
		&__boxButton {
			text-align: center;
			margin: 75px 0 0;
			@include max-screen(767px) {
				margin: 25px 0 0;
			}
			.check {
				@include font-size(16);
				font-weight: 600;
				color: #1E1E1E;
				@include max-screen(767px) {
					max-width: 260px;
					margin: 0 auto;
					@include font-size(14);
				}
				input[type="checkbox"] {
					position: relative;
					top: -2px;
					margin: 0 10px 0 0;
					@include max-screen(767px) {
						top: 1px;
						margin: 0 5px 0 0;
					}
				}
				label {
					@include max-screen(767px) {
						background: #E6E6E6;
						line-height: 50px;
						display: block;
					}
				}
			}
			button {
				max-width: 360px;
				width: 100%;
				color: #fff;
				text-align: center;
				border: none;
				padding: 0 10px;
				line-height: 60px;
				background: rgba(235,57,83,0.5);
				@include font-size(18);
				font-weight: 600;
				@include border-radius(0px);
				outline: 0;
				cursor: pointer;
				@include transition(all 0.3s);
				position: relative;
				margin: 30px 0 0;
				@include max-screen(767px) {
					max-width: 260px;
					margin: 20px 0 0;
				}
				&:before {
					content: '';
					background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
					background-size: 6px 9px;
					width: 6px;
					height: 9px;
					position: absolute;
					top: 50%;
					right: 20px;
					margin-top: -4.5px;
				}
				&:hover {
					background: #EB3953;
				}
			}
			&__step2 {
				margin: 70px 0 0;
				button {
					margin: 0 auto;
				}
			}
		}
		&__boxButton02 {
			margin: 60px 0 0;
			@include max-screen(767px) {
				margin: 25px 0 0;
			}
		}
		&__textBottom {
			text-align: center;
			@include font-size(13);
			line-height: 1.53em;
			margin: 50px 0 0;
			@include max-screen(767px) {
				padding: 0 15px;
				margin: 25px 0 0;
			}
			a {
				color: inherit;
				text-decoration: none;
				@include max-screen(767px) {
					text-decoration: underline;
				}
			}
		}
	}
	&__infoContact {
		margin: 40px 0 0;
		h3 {
			text-align: center;
			line-height: 90px;
			color: #fff;
			background: #36CFC6;
			font-weight: 600;
			@include font-size(24);
			letter-spacing: 0.05em;
			@include max-screen(767px) {
				@include font-size(18);
				line-height: 50px;
			}
		}
		&__info {
			.ttl {
				text-align: center;
				font-weight: 600;
				@include font-size(18);
				margin: 40px 0 0;
				@include max-screen(767px) {
					@include font-size(15);
					line-height: 1.7em;
					margin: 20px 0 0;
					padding: 0 15px;
				}
				@include max-screen(320px) {
					@include font-size(14);
				}
			}
			&__boxtel {
				max-width: 504px;
				margin: 25px auto 0;
				background: #F7F7F7;
				@include max-screen(767px) {
					max-width: 315px;
					margin: 10px auto 0;
				}
				@include max-screen(320px) {
					max-width: 290px;
				}
				a {
					display: block;
					@include border-radius(5px);
					overflow: hidden;
					p {
						@include font-size(28);
						font-family: YuGothic, 'YuGothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
						color: #1E1E1E;
						text-align: left;
						font-weight: 600;
						letter-spacing: 0.05em;
						line-height: 1em;
						padding: 25px 0 25px 90px;
						position: relative;	
						@include max-screen(767px) {
							padding: 13px 0 5px 30px;
							text-align: center;
						}
						&.font-mobile {
							font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Yu Gothic", YuGothic, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
							font-feature-settings: "pkna";
							letter-spacing: 0;
							span {
								padding: 0;
								font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Yu Gothic", YuGothic, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
								font-feature-settings: "pkna";
							}
						}
						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						   /* IE10+ CSS */
						   font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
						}
						@supports (-ms-accelerator:true) {
						   /* IE Edge 12+ CSS */ 
						   font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
						}
						&:before {
							content: '';
							background: url('../img/common/ico_tel.svg') no-repeat 0 0;
							background-size: 32px 21px;
							width: 32px;
							height: 21px;
							position: absolute;
							top: 50%;
							left: 42px;
							margin-top: -12px;
							@include max-screen(767px) {
								left: 37px;
								margin-top: -16px;
							}
							@include max-screen(320px) {
								left: 20px;
							}
						}
						span {
							display: inline-block;
							vertical-align: middle;
							@include font-size(13);
							font-weight: 300;
							letter-spacing: 0;
							text-align: left;
							padding: 0 0 0 20px;
							@include max-screen(767px) {
								display: block;
								text-align: center;
								padding: 0;
								margin: 0 0 0 -30px;
								line-height: 1.6em;
							}
						}
					}
					small {
						@include font-size(12);
						text-align: center;
						display: none;
						background: #585858;
						color: #fff;
						@include max-screen(767px) {
							display: block;
						}
					}
				}
			}
		}
	}
	&__choiceForm {
		background: #F7F7F7;
		padding: 55px 0 90px;
		margin: 50px 0 0;
		@include max-screen(767px) {
			padding: 35px 0 45px;
			margin: 30px 0 0;
		}
		h3 {
			text-align: center;
			@include font-size(22);
			font-weight: 600;
			line-height: 1.375em;
			color: #1E1E1E;
			letter-spacing: 0.05em;
			@include max-screen(767px) {
				@include font-size(16);
				line-height: 1.375em;
			}
		}
		&__wrap {
			@include clearfix();
			margin: 25px -22px 0;
			padding: 0 36px;
			@include min-screen(768px) {
				padding: 0;
			}
			@include min-screen(992px) {
				padding: 0 36px;
			}
			@include max-screen(767px) {
				margin: 0;
				padding: 0;
			}
			&__item {
				float: left;
				width: 33.333%;
				padding: 0 22px;
				position: relative;
				@include max-screen(767px) {
					float: none;
					width: 100%;
					padding: 0;
					margin: 20px 0 0;
				}
				.boxInner {
					@include border-radius(100%);
					text-align: center;
					min-height: 280px;
					@include min-screen(768px) {
						min-height: 224px;
					}
					@include min-screen(992px) {
						min-height: 280px;
					}
					@include max-screen(767px) {
						@include border-radius(0);
						min-height: 155px;
					}
				}
				&__info {
					padding: 35px 40px 0;
					@include min-screen(768px) {
						padding: 35px 20px 0;
					}
					@include min-screen(992px) {
						padding: 35px 40px 0;
					}
					@include max-screen(767px) {
						float: left;
						width: 110px;
						padding: 0;
					}
					h4 {
						@include font-size(14);
						line-height: 1.42em;
						font-weight: 600;
						color: #fff;
						@include min-screen(768px) {
							@include font-size(12);
						}
						@include min-screen(992px) {
							@include font-size(14);
						}
						@include max-screen(767px) {
							text-align: left;
							@include font-size(12);
							line-height: 1.3em;
							padding: 10px 0 0 10px;
							font-weight: 300;
						}
					}
					h5 {
						@include font-size(16);
						line-height: 1.5em;
						border-top: 1px solid #fff;
						color: #fff;
						margin: 10px 0 0;
						padding: 10px 0 0;
						@include min-screen(768px) {
							@include font-size(14);
						}
						@include min-screen(992px) {
							@include font-size(16);
						}
						@include max-screen(767px) {
							text-align: left;
							padding: 8px 0 0 10px;
						}
					}
				}
				&__img {
					padding: 5px 0 11px;
					@include max-screen(767px) {
						float: left;
						width: calc(100% - 110px);
						padding: 0 0 0 15px;
					}
					img {
						@include min-screen(768px) {
							width: 60%;
						}
						@include min-screen(992px) {
							width: auto;
						}
					}
				}
				&__btn {
					position: absolute;
					bottom: -20px;
					left: 50%;
					@include translate(-50%,0);
					width: 100%;
					@include max-screen(767px) {
						bottom: 0;
						left: 0;
						@include translate(0,0);
					}
					label {
						background: #585858;
						color: #fff;
						@include font-size(13);
						font-weight: 600;
						padding: 15px 30px;
						@include max-screen(767px) {
							display: block;
							@include font-size(15);
							padding: 11px 20px;
						}
						input[type="checkbox"] {
							position: relative;
							top: -1px;
							margin: 0 5px 0 0;
							outline: 0;
							@include min-screen(768px) {
								top: 2px;
							}
							@include min-screen(992px) {
								top: -1px;
							}
							@include max-screen(767px) {
								top: 1px;
							}
							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						   /* IE10+ CSS */
							   top: 2px;
							}
							@supports (-ms-accelerator:true) {
							   /* IE Edge 12+ CSS */ 
							   top: 2px;
							}
						}
					}
				}
				&:nth-child(1) {
					.boxInner {
						background: #36CFC6;
						label {
							@include max-screen(767px) {
								background: #2EAAA3;
							}
						}
					}
				}
				&:nth-child(2) {
					.boxInner {
						background: #FAB700;
						label {
							@include max-screen(767px) {
								background: #EF9608;
							}
						}
					}
					.subpage__choiceForm__wrap__item__img {
						img {
							@include max-screen(767px) {
								position: relative;
								top: -6px;
							}
						}
					}
				}
				&:nth-child(3) {
					.boxInner {
						background: #00A3C7;
						label {
							@include max-screen(767px) {
								background: #038CAA;
							}
						}
					}
					.subpage__choiceForm__wrap__item__info {
						@include max-screen(767px) {
							width: 150px;
						}
					}
					.subpage__choiceForm__wrap__item__img {
						@include max-screen(767px) {
							width: calc(100% - 150px);
						}
						img {
							@include max-screen(767px) {
								position: relative;
								top: -6px;
								width: 85%;
							}
						}
					}
				}
			}
		}
	}
	&__boxCalendar {
		margin: 55px 0 0;
		text-align: center;
		@include max-screen(767px) {
			margin: 30px 0 55px;
		}
		h4 {
			@include font-size(22);
			font-weight: 600;
			text-align: center;
			line-height: 1.2em;
			letter-spacing: 0.05em;
			@include max-screen(767px) {
				@include font-size(20);
			}
			span {
				border-bottom: 4px solid #36CFC6;
				padding: 0 20px 10px;
				display: inline-block;
				@include max-screen(767px) {
					padding: 0 10px 10px;
				}
			}
		}
		&__inner {
			max-width: 640px;
			height: 476px;
			margin: 10px auto 0;
			@include max-screen(767px) {
				padding: 0 15px;
				height: 230px;
				margin: 30px auto 0;
			}
		}
	}
}