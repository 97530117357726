.column {
	&__detail {
		&__inner {
			max-width: 840px;
			margin: 65px auto 0;
			@include max-screen(767px) {
				margin: 30px auto 0;
			}
		}
		&__boxImg {
			text-align: center;
			&__inner {
				display: inline-block;
				position: relative;
				line-height: 0;
				figure {
					line-height: 0;
					text-align: center;
					display: inline-block;
					img {
						max-height: 518px;
						@include max-screen(767px) {
							max-height: 100%;
						}
					}
				}
			}
			.box-cate {
				position: absolute;
				top: 0;
				left: 0;
				.cate-label {
					@include font-size(13);
					line-height: 24px;
					text-align: center;
					float: left;
					color: #fff;
					padding: 0 11px;
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 27px;
					}
					@include max-screen(320px) {
						@include font-size(12);
					}
				}
			}
		}
		&__boxInfo {
			&__social {
				max-width: 600px;
				margin: 25px auto 0;
				@include clearfix();
				@include max-screen(767px) {
					margin: 15px auto 0;
				}
				li {
					float: left;
					line-height: 0;
					width: 25%;
					position: relative;
					overflow: hidden;
					img {
						width: 100%;
					}
					&.line-btn {
						span {
							position: absolute;
							@include scale(8);
							opacity: 0;
						}
					}
				}
			}
			.h2_cms {
				@include font-size(24);
				@include max-screen(767px) {
					@include font-size(19);
					line-height: 1.57em;
				}
			}
		}
		&__boxContent {
			margin: 60px 0 0;
			@include max-screen(767px) {
				border-top: 1px solid #E6E6E6;
				margin: 20px 0 0;
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				margin: 20px 0 0;
				@include clearfix();
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.71em;
				}
				img {
					max-width: 700px;
					max-width: 700px;
					height: auto;
					max-height: 700px;
					width: auto;
					@include max-screen(767px) {
						max-width: 100%;
						width: 100%;
						max-height: 100%;
					}
					&.vt-img {
						max-height: 700px;
						max-width: 100%;
						@include max-screen(767px) {
							max-height: auto;
							width: 100%;
						}
					}
				}
			}
			h2 {
				@include font-size(22);
				font-weight: 600;
				line-height: 1.45em;
				text-align: left;
				margin: 35px 0 0;
				color: #1E1E1E;
				@include max-screen(767px) {
					@include font-size(19);
					line-height: 1.57em;
					margin: 20px 0 0;
				}
			}
			h3 {
				@include font-size(17);
				color: #1E1E1E;
				line-height: 1.52em;
				font-weight: 600;
				border-bottom: 1px solid #1594B0;
				padding: 0 0 10px;
				margin: 30px 0 0;
				@include max-screen(767px) {
					margin: 20px 0 0;
				}
			}
			h4 {
				@include font-size(20);
				line-height: 1.4em;
				font-weight: 600;
				color: #1E1E1E;
				position: relative;
				padding: 0 0 0 20px;
				margin: 45px 0 0;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.3em;
					padding: 0 0 0 15px;
					margin: 40px 0 0;
				}
				&:before {
					content: '';
					width: 4px;
					height: 26px;
					background: #1594B0;
					position: absolute;
					top: 0;
					left: 0;
					@include max-screen(767px) {
						height: 100%;
					}
				}
			}
			h5 {
				@include font-size(18);
				color: #1594B0;
				line-height: 1.22em;
				font-weight: 600;
				margin: 40px 0 0;
				@include max-screen(767px) {
					@include font-size(15);
					line-height: 1.46em;
					margin: 30px 0 0;
				}
			}
		}
		&__boxRecommend {
			margin: 120px 0 0;
			@include max-screen(767px) {
				margin: 60px 0 0;
			}
			&__ttl {
				max-width: 580px;
				margin: 0 auto;
				position: relative;
				@include font-size(25);
				font-weight: 600;
				line-height: 60px;
				background: #1594B0;
				text-align: center;
				color: #fff;
				@include max-screen(767px) {
					@include font-size(18);
					line-height: 35px;
					max-width: 100%;
				}
				&:before {
					content: '';
					width: 0; 
					height: 0; 
					border-left: 12px solid transparent;
					border-right: 12px solid transparent;
					border-top: 6px solid #1594B0;
					position: absolute;
					bottom: -6px;
					left: 50%;
					margin-left: -12px;
					@include max-screen(767px) {
						border-left: 15px solid transparent;
						border-right: 15px solid transparent;
						border-top: 8px solid #1594B0;
						position: absolute;
						bottom: -8px;
						left: 50%;
						margin-left: -15px;
					}
				}
			}
			&__wrap {
				@include max-screen(767px) {
					overflow-x: scroll;
					margin: 0 auto;
					@include clearfix();
				}
			}
			&__wrapItem {
				@include clearfix();
				margin: 20px -15px 0;
				@include max-screen(767px) {
					margin: 20px auto 0;
					width: 536px;
					text-align: center;
				}
				&__item {
					padding: 0 15px;
					@include max-screen(767px) {
						padding: 0 8px;
						width: 176px;
						display: inline-block;
						float: none;
					}
					figure {
						line-height: 0;
						img {
							width: 100%;
						}
					}
					.ttl {
						@include font-size(15);
						line-height: 1.46em;
						font-weight: 600;
						color: #1E1E1E;
						margin: 15px 0 0;
						text-align: left;
					}
				}
			}
		}
	}
}