@charset 'UTF-8';

.totop {
	position: fixed;
	bottom: 50px;
	right: 50px;
	z-index: 1000;
	line-height: 0;
	@include transition(all 0.3s);
	@include max-screen(767px) {
		bottom: 20px;
		right: 20px;
		img {
			width: 40px;
		}
		&.active {
			right: 20px;
			bottom: 85px;
		}
	}
}
/*-----------------------------------------------------------
Footer
------------------------------------------------------------*/
.footer {
	@include max-screen(767px) {
		margin: 50px 0 0;
	}
	&__boxContact {
		background: #D3E7F5;
		&__inner {
			max-width: 905px;
			margin: 0 auto;
			padding: 0 15px;
			@include clearfix();
			min-height: 125px;
			@include max-screen(767px) {
				min-height: auto;
				padding: 30px;
			}
			@include max-screen(320px) {
				padding: 20px;
			}
		}
		&__boxLeft {
			position: relative;
			float: left;
			width: 205px;
			@include min-screen(768px) {
				width: 170px;
			}
			@include min-screen(992px) {
				width: 205px;
			}
			@include max-screen(767px) {
				width: 100%;
				float: none;
			}
			.img-txt {
				position: absolute;
				top: -17px;
				left: 0;
				@include max-screen(767px) {
					display: none;
				}
			}
			.txt {
				@include font-size(17);
				font-weight: 600;
				line-height: 1.52em;
				letter-spacing: 0.05em;
				padding: 40px 0 0;
				@include min-screen(768px) {
					@include font-size(14);
					padding: 43px 0 0;
				}
				@include min-screen(992px) {
					@include font-size(17);
					padding: 40px 0 0;
				}
				@include max-screen(767px) {
					@include font-size(15);
					padding: 0;
					text-align: center;
				}
				@include max-screen(320px) {
					@include font-size(13);
				}
			}
		}
		&__boxRight {
			width: calc(100% - 205px);
			float: left;
			@include min-screen(768px) {
				width: calc(100% - 170px);
			}
			@include min-screen(992px) {
				width: calc(100% - 205px);
			}
			@include max-screen(767px) {
				width: 100%;
				float: none;
				max-width: 100%;
			}
			&__boxmail {
				float: right;
				max-width: 315px;
				width: 100%;
				padding: 25px 0 0;
				@include min-screen(768px) {
					max-width: 280px;
				}
				@include min-screen(992px) {
					max-width: 315px;
				}
				@include max-screen(767px) {
					width: 100%;
					float: none;
					max-width: 100%;
					padding: 0;
					margin: 15px 0 0;
					display: table;
				}
				a {
					display: block;
					padding: 12px 21px 0 60px;
					background: #EB3953;
					color: #fff;
					height: 74px;
					@include border-radius(5px);
					overflow: hidden;
					position: relative;
					@include max-screen(767px) {
						display: table-cell;
						width: 100%;
						vertical-align: middle;
						padding: 0 10px 0 60px;
					}
					&:before {
						content: '';
						background: url('../img/common/ico_mail.svg') no-repeat 0 0;
						background-size: 31px 25px;
						width: 31px;
						height: 25px;
						position: absolute;
						top: 50%;
						left: 20px;
						margin-top: -12.5px;
					}
					small {
						display: block;
						@include font-size(13);
						font-weight: 600;
						@include min-screen(768px) {
							@include font-size(11);
						}
						@include min-screen(992px) {
							@include font-size(13);
						}
						@include max-screen(767px) {
							text-align: center;
						}
						@include max-screen(320px) {
							@include font-size(10);
						}
					}
					span {
						@include font-size(20);
						font-weight: 600;
						@include min-screen(768px) {
							@include font-size(18);
						}
						@include min-screen(992px) {
							@include font-size(20);
						}
						@include max-screen(767px) {
							text-align: center;
							display: block;
						}
						@include max-screen(320px) {
							@include font-size(16);
						}
					}
				}
			}
			&__boxtel {
				float: right;
				max-width: 325px;
				width: 100%;
				padding: 33px 35px 0 0;
				@include min-screen(768px) {
					max-width: 260px;
					padding: 43px 20px 0 0;
				}
				@include min-screen(992px) {
					max-width: 336px;
					padding: 33px 30px 0 0;
				}
				@include max-screen(767px) {
					width: 100%;
					float: none;
					max-width: 100%;
					padding: 0;
					margin: 15px 0 0;
				}
				a {
					display: block;
					@include border-radius(5px);
					overflow: hidden;
					p {
						@include font-size(33);
						font-family: YuGothic, 'YuGothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
						color: #1E1E1E;
						text-align: center;
						font-weight: 600;
						letter-spacing: 0.05em;
						line-height: 1em;
						padding: 0 0 0 50px;
						position: relative;
						@include min-screen(768px) {
							@include font-size(24);
							text-align: left;
						}
						@include min-screen(992px) {
							@include font-size(33);
							text-align: center;
						}
						@include max-screen(768px) {
							letter-spacing: 0;
						}
						@include max-screen(767px) {
							background: #fff;
							line-height: 1.2em;
							@include font-size(28);
							padding: 10px 0 5px 30px;
						}
						&.font-mobile {
							font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Yu Gothic", YuGothic, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
							font-feature-settings: "pkna";
							letter-spacing: 0;
						}
						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						   /* IE10+ CSS */
						   font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
						}
						@supports (-ms-accelerator:true) {
						   /* IE Edge 12+ CSS */ 
						   font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
						}

						&:before {
							content: '';
							background: url('../img/common/ico_tel.svg') no-repeat 0 0;
							background-size: 39px 26px;
							width: 39px;
							height: 26px;
							position: absolute;
							top: 4px;
							left: 0;
							@include max-screen(767px) {
								top: 50%;
								left: 37px;
								margin-top: -17px;
								background-size: 32px 21px;
								width: 32px;
								height: 21px;
							}
							@include max-screen(320px) {
								left: 20px;
								margin-top: -14px;
							}
						}
						span {
							display: block;
							@include font-size(13);
							font-weight: 300;
							letter-spacing: 0;
							text-align: left;
							padding: 0 0 0 10px;
							@include min-screen(768px) {
								@include font-size(11);
							}
							@include min-screen(992px) {
								@include font-size(13);
							}
							@include max-screen(767px) {
								text-align: center;
								padding: 0;
								line-height: 1.4em;
								margin: 0 0 0 -30px;
							}
							@include max-screen(320px) {
								@include font-size(11);
							}
						}
					}
					small {
						@include font-size(12);
						text-align: center;
						display: none;
						background: #585858;
						color: #fff;
						@include max-screen(767px) {
							display: block;
						}
					}
				}
			}
		}
	}
	&__boxLink {
		@include clearfix();
		padding: 35px 0 50px;
		@include max-screen(767px) {
			padding: 0;
		}
		&__boxLeft {
			float: left;
			width: 65%;
			@include max-screen(767px) {
				float: none;
				width: 100%;
			}
		}
		&__boxRight {
			float: right;
			width: 35%;
			padding: 10px 35px 0 0;
			@include max-screen(767px) {
				float: none;
				width: 100%;
				padding: 0;
				text-align: center;
			}
		}
		&__linkPC {
			@include clearfix();
			@include max-screen(767px) {
				display: none;
			}
			&__item {
				float: left;
				width: 40%;
				&:last-child {
					width: 60%;
				}
				ul {
					@include clearfix();
					li {
						padding: 7px 0;
						a {
							@include font-size(14);
							color: #0A0A0A;
							letter-spacing: 0.025em;
							line-height: 1.85em;
							@include min-screen(768px) {
								@include font-size(10);
							}
							@include min-screen(992px) {
								@include font-size(14);
							}
						}
					}
					&.col-2 {
						li {
							width: 40%;
							float: left;
							&.ttl {
								width: 100%;
								a {
									font-weight: 600;
								}
							}
						}
					}
					&.link-privacy {
						margin: 10px 0 0;
						li {
							float: left;
							padding: 0 15px 0 0;
							&:last-child {
								padding: 0;
							}
							a {
								text-decoration: underline;
								@include font-size(13);
								@include min-screen(768px) {
									@include font-size(11);
								}
								@include min-screen(992px) {
									@include font-size(13);
								}
							}
						}
					}
					&.col-3-item {
						width: 30.5%;
						float: left;
						&:nth-child(1) {
							width: 39%;
						}
					}
				}
			}
		}
		&__linkSP {
			width: 100%;
			float: none;
			display: none;
			@include max-screen(767px) {
				display: block;
			}
			ul.main-link {
				@include clearfix();
				margin: 0 -15px;
				li {
					width: 50%;
					float: left;
					border-bottom: 1px solid #E6E6E6;
					border-right: 1px solid #E6E6E6;
					&.w100 {
						width: 100%;
						border-right: none;
					}
					&.no-bd {
						border-right: none;
					}
					a {
						@include font-size(14);
						letter-spacing: 0.025em;
						line-height: 1.85em;
						padding: 12px 15px;
						display: block;
						position: relative;
						@include max-screen(767px) {
							letter-spacing: 0;
						}
						&:before {
							content: '';
							background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
							background-size: 6px 9px;
							width: 6px;
							height: 9px;
							top: 50%;
							right: 10px;
							margin-top: -4.5px;
							position: absolute;
						}
					}
				}
			}
			ul.link-privacy {
				text-align: center;
				margin: 25px 0 0;
				li {
					display: inline-block;
					text-align: center;
					padding: 0 15px;
					a {
						@include font-size(13);
						color: #0A0A0A;
						text-decoration: underline;
						@include max-screen(320px) {
							@include font-size(12);
						}
						span {
							position: relative;
							padding: 0 10px 0 0;
							&:before {
								content: '';
								background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
								background-size: 6px 9px;
								width: 6px;
								height: 9px;
								position: absolute;
								top: 50%;
								right: 0;
								margin-top: -4.5px;
							}
						}
					}
				}
			}
		}	
		&__boxLogo {
			width: 170px;
			float: right;
			@include min-screen(768px) {
				width: 145px;
			}
			@include min-screen(992px) {
				width: 170px;
			}
			@include max-screen(767px) {
				width: 100%;
				float: none;
				margin: 25px 0 0;
			}
			.address {
				@include font-size(14);
				line-height: 1.57em;
				margin: 15px 0 0;
				@include min-screen(768px) {
					@include font-size(12);
				}
				@include min-screen(992px) {
					@include font-size(14);
				}
				@include max-screen(767px) {
					@include font-size(14);
					margin: 5px 0 0;
					letter-spacing: 0.02em;
				}
				span {
					@include font-size(16);
					font-weight: 600;
					padding: 0 0 5px;
					display: block;
					@include min-screen(768px) {
						@include font-size(14);
					}
					@include min-screen(992px) {
						@include font-size(16);
					}
					@include max-screen(767px) {
						display: none;
					}
				}
			}
		}
		.copyright {
			padding: 20px 0 0;
			clear: both;
			text-align: right;
			@include font-size(10);
			letter-spacing: 0.025em;
			color: #585858;
			font-family: YuGothic, 'YuGothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
			@include max-screen(767px) {
				background: #585858;
				text-align: center;
				margin: 20px -15px 0;
				color: #fff;
				line-height: 32px;
				padding: 0;
			}
		}
	}
}
