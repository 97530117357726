// Author: Kyohei Maeda
// modify by Kyohei Maeda 20160812

//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

//font-size with rem
//==================================================
@mixin font-size($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
}

@mixin my_menu_effect($items, $delay, $direct) {
	@each $item in $items {
		&:nth-child(#{$item}) {
			@include transition_c(#{$delay}ms, 'delay');
		}

		@if ($direct == "open") {
			$delay: $delay + 50;
		} @else {
			$delay: $delay - 50;
		}
	}
}

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");

@mixin transition_c ($content, $opt: "") {
	@each $prefix in $browser_prefix {
		@if ($opt == "") {
			#{$prefix}transition: $content;
		} @else {
			#{$prefix}transition-#{$opt}: $content;
		}
	}
}

@mixin transform_c ($content) {
	@each $prefix in $browser_prefix {
		#{$prefix}transform: $content;
	}
}

@mixin calc-css ($prefix, $content) {
    #{$prefix}: -webkit-calc($content);
    #{$prefix}: -moz-calc($content);
    #{$prefix}: calc($content);
}

@mixin border ($content, $side: "") {
	@if ($side == "") {
		border: #{$content};
	} @else {
		border-#{$side}: #{$content};
	}
}

@mixin border-radius ($radius, $side: "") {
	@if ($side == "" ) {
		-webkit-border-radius: $radius;
		-moz-border-radius: $radius;
		border-radius: $radius;
	} @else {
		-webkit-border-#{$side}-radius: $radius;
		-moz-border-#{$side}-radius: $radius;
		border-#{$side}-radius: $radius;
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}
@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}