@charset 'UTF-8';

#privacy {
	// 
	.subpage__mainvisual {
	    background: url(../img/privacy/img_main.jpg) no-repeat center top;
	    background-size: cover;
	    @include max-screen(767px) {
		    background: url(../img/privacy/img_main_sp.jpg) no-repeat center top;
		    background-size: cover;
	    }
	}
	.privacy_content {
		font-size: 15px;
		max-width: 880px;
		padding: 0 20px;
		margin: 50px auto 115px;
		.ul_privacy	{
			li {
				margin-top: 40px;
				h3 {
					font-weight: bold;
					font-size: 18px;
					border-bottom: 2px solid #1594B0;
					line-height: 1;
					padding-bottom: 10px;
					margin-bottom: 20px;
				}
				p {
					margin-bottom: 30px;
					&:last-child {margin-bottom: 0;}
				}
			}
		}
		@include max-screen(767px) {
			font-size: 14px;
			margin: 30px auto 25px;
			.ul_privacy	{
				li {
					margin-top: 30px;
					h3 {
						font-size: 17px;
						margin-bottom: 17px;
						line-height: 1.4;
						padding-bottom: 7px;
					}
					p {
						margin-bottom: 20px;
						&:last-child {margin-bottom: 0;}
					}
				}
			}
		}
	}
}