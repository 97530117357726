.modelhouse {
	.totop {
		&.active {
			bottom: 20px;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/modelhouse/modelhouse_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/modelhouse/modelhouse_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__h3 {
		font-weight: 600;
		@include font-size(19);
		line-height: 1.4em;
		position: relative;
		padding: 0 0 0 15px;
		@include max-screen(767px) {
			@include font-size(17);
			line-height: 1.5em;
		}
		&:before {
			content: '';
			width: 4px;
			height: 24px;
			background: #36CFC6;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&__btn {
		margin: 20px auto 0;
		max-width: 320px;
		@include max-screen(767px) {
			max-width: 240px;
		}
		a {
			position: relative;
			line-height: 60px;
			@include font-size(16);
			@include max-screen(767px) {
				line-height: 44px;
				@include font-size(13);
			}
			&:before {
				content: '';
				background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
				background-size: 6px 9px;
				width: 6px;
				height: 9px;
				position: absolute;
				top: 50%;
				right: 15px;
				margin-top: -4.5px;
				@include rotate(90);
			}
		}
	}
	&__article {
		&__boxInfo {
			margin: 55px 0 0;
			@include max-screen(767px) {
				margin: 30px 0 0;
			}
		}
		&__boxImg {
			margin: 45px 0 0;
			@include max-screen(767px) {
				margin: 30px 0 0;
			}
			figure {
				line-height: 0;
				img {
					width: 100%;
				}
			}
			h2 {
				margin: 40px 0 0;
				@include max-screen(767px) {
					margin: 25px 0 0;
				}
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				text-align: center;
				margin: 25px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.7em;
					text-align: left;
				}
			}
		}
		&__tbl {
			@include clearfix();
			margin: 30px 0 0;
			@include max-screen(767px) {
				margin: 20px 0 0;
			}
			.model-tbl {
				border: 1px solid #E6E6E6;
				float: left;
				width: 50%;
				@include max-screen(767px) {
					width: 100%;
					float: none;
				}
				&.model-tbl2 {
					border-left: transparent;
					@include max-screen(767px) {
						border-left: 1px solid #e6e6e6;
						border-top: transparent;
					}
				}
				tr {
					border-bottom: 1px solid #E6E6E6;
					th {
						background: #F7F7F7;
						@include font-size(14);
						font-weight: 600;
						line-height: 1.85em;
						width: 18%;
						height: 70px;
						vertical-align: middle;
						@include max-screen(767px) {
							height: auto;
							width: 93px;
							vertical-align: top;
							padding: 15px;
							text-align: left;
						}
					}
					td {
						width: 82%;
						@include font-size(14);
						line-height: 1.57em;
						padding: 13px 20px;
						vertical-align: middle;
						@include max-screen(767px) {
							width: auto;
							padding: 15px;
						}
						a.link-map {
							color: #1594B0;
							text-decoration: underline;
							position: relative;
							padding: 0 10px 0 0;
							display: inline-block;
							&:before {
								content: '';
								background: url('../img/common/ico_arrow_cyan.svg') no-repeat 0 0;
								background-size: 6px 9px;
								width: 6px;
								height: 9px;
								position: absolute;
								top: 50%;
								right: 0;
								margin-top: -4.5px;
							}
						}
						a.tel {
							pointer-events: none;
							@include max-screen(767px) {
								text-decoration: underline;
								pointer-events: auto;
							}
						}
					}
				}
			}
		}
		&__boxContent {
			margin: -30px 0 0;
			@include max-screen(767px) {
				margin: 0;
			}
			&__wrapItem {
				@include clearfix();
				margin: 100px -20px 0;
				@include max-screen(767px) {
					margin: 35px 0 0;
				}
				&__boxImg {
					width: 43%;
					float: left;
					padding: 0 20px;
					@include max-screen(767px) {
						width: 100%;
						float: none;
						padding: 0;
					}
					figure {
						line-height: 0;
						img {
							width: 100%;
						}
					}
				}
				&__boxText {
					width: 57%;
					padding: 0 20px;
					float: left;
					margin: -5px 0 0;
					@include max-screen(767px) {
						float: none;
						width: 100%;
						padding: 0;
						margin: 0;
					}
					p {
						@include font-size(15);
						line-height: 1.86em;
						margin: 0 0 30px;
						@include max-screen(767px) {
							@include font-size(14);
							line-height: 1.7em;
							margin: 10px 0 0;
						}
					}
				}
				&__subWrap {
					margin: 110px -10px 0;
					@include max-screen(767px) {
						margin: 10px 0 0;
					}
					@include clearfix();
					&__subItem {
						padding: 0 10px;
						@include max-screen(767px) {
							padding: 0;
							margin: 30px 0 0;
						}
						figure {
							line-height: 0;
							img {
								width: 100%;
							}
						}
						p {
							@include font-size(14);
							line-height: 1.7em;
							margin: 15px 0 0;
							@include max-screen(767px) {
								margin: 10px 0 0;
							}
						}
					}
				}
			}
		}
		&__boxMap {
			margin: 60px 0 0;
			@include max-screen(767px) {
				margin: 40px 0 0;
			}
			&__map {
				margin: 30px 0 0;
				@include max-screen(767px) {
					margin: 15px 0 0;
				}
				iframe {
					height: 380px;
					@include max-screen(767px) {
						height: 284px;
					}
				}
				p {
					@include font-size(15);
					line-height: 1.46em;
					margin: 15px 0 0;
					@include max-screen(767px) {
						@include font-size(14);
						line-height: 1.7em;
						margin: 0;
					}
				}
			}
			&__btn {
				max-width: 240px;
				margin: 35px auto 0;
				@include max-screen(767px) {
					margin: 20px auto 0;
				}
				a {
					line-height: 44px;
					@include font-size(13);
					position: relative;
					&:before {
						content: '';
						background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
						background-size: 6px 9px;
						width: 6px;
						height: 9px;
						position: absolute;
						top: 50%;
						right: 20px;		
						margin-top: -4.5px;
					}
				}
			}
		}
	}
}