// alive-vn [Thinh]
$color_yellow : #FA9F0C;
$color_green : #36CFC6;
$color_blue : #25BDE7;
$color_pink : #FA86AF;
$color_yellow2 : #EBF414;
// 
.servicePage .subpage__mainvisual {
    background: url(../img/service/img_main.jpg) no-repeat center top;
    background-size: cover;
    @include max-screen(767px) {
	    background: url(../img/service/img_main_sp.jpg) no-repeat center top;
	    background-size: cover;
    }
}
// 
.imgMax {
	@include max-screen(767px) {
		width: 100%;
	}
}

.bHead {
	background: url(../img/common/bg_head_left.svg) no-repeat left center,url(../img/common/bg_head_right.svg) no-repeat right center;
	background-size: 218px auto, 218px auto;
	background-color: rgba(250,159,12,0.1);
	text-align: center;
	@include font-size(22);
	font-weight: bold;
	height: 112px;
	position: relative;
	margin-top: 20px;
	margin-bottom: 50px;
	letter-spacing: 0.15em;
	span {
		line-height: 1.4;
        display: block;
        width:100%;
        position: absolute;
        top:50%;
        z-index:100;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
		em {color: $color_yellow; font-style: normal; @include font-size(25);}
		
	}
	&.green {
		background: url(../img/common/bg_head1_left.svg) no-repeat left center,url(../img/common/bg_head1_right.svg) no-repeat right center;
		background-size: 218px auto, 218px auto;
		background-color: rgba(54,207,198,0.1);
		span {
			em {color: $color_green; font-style: normal;}
		}
	}
	&.blue {
		background: url(../img/common/bg_head3_left.svg) no-repeat left center,url(../img/common/bg_head3_right.svg) no-repeat right center;
		background-size: 218px auto, 218px auto;
		background-color: rgba(37,189,231,0.1);
		span {
			em {color: $color_blue; font-style: normal;}
		}
	}
	&.pink {
		background: url(../img/common/bg_head2_left.svg) no-repeat left center,url(../img/common/bg_head2_right.svg) no-repeat right center;
		background-size: 218px auto, 218px auto;
		background-color: rgba(250,134,175,0.1);
		span {
			em {color: $color_pink; font-style: normal;}
		}
	}
	&.yellow {
		background: url(../img/common/bg_head_left.svg) no-repeat left center,url(../img/common/bg_head_right.svg) no-repeat right center;
		background-size: 218px auto, 218px auto;
		background-color: rgba(250,159,12,0.1);
		span {
			em {color: $color_yellow; font-style: normal;}
		}
	}
	@include max-screen(767px) {
		background-image: none!important;
		background-color: rgba(250,159,12,0.1);
		@include font-size(16);
		height: auto;
		margin-bottom: 20px;
		padding: 10px 6px;
		letter-spacing: 0;
		span {
	        position: static;
	        -webkit-transform: none;
	        -ms-transform: none;
	        transform: none;
	        em {@include font-size(20);}
		}
		&.green {
		background-image: none;
			background-color: rgba(54,207,198,0.1);
		}
		&.blue {
		background-image: none;
			background-color: rgba(37,189,231,0.1);
		}
	}
}


// sHead2
.sHead2 {
	background: #F7F7F7;
	border-bottom: 2px solid $color_yellow;
	padding: 10px 50px;
	@include border-radius(8px 8px 0 0);
	position: relative;
	>span {
		display: inline-block;
		vertical-align: top;
		width: 38px;
		height: 38px;
		@include border-radius(50%);
		color: #fff;
		text-align: center;
		line-height: 38px;
		font-weight: bold;
		@include font-size(21);
		font-family: 'Helvetica Neue';
		margin-right: 15px;
		background: $color_yellow;
		position: relative;
		&:after {
			position: absolute;
			content: 'STEP';
			color: #fff;
			background: $color_yellow;
			font-family: 'Helvetica Neue';
			@include font-size(18);
			font-weight: bold;
			width: 67px;
			text-align: center;
			line-height: 28px;
			height: 28px;
			top: 50%;
			margin-top: -14px;
			left: -60px;
			z-index: 0;
			@include border-radius(14px 0 0 14px);
		}
	}
	>strong {
		display: inline-block;
		vertical-align: top;
		@include font-size(19);
		line-height: 1.4;
		padding-top: 5px;
		em {
			font-style: normal;
			color: $color_yellow;
		}
	}
	@include max-screen(767px) {
		padding: 8px 37px;
		margin: 0 20px;
		>span {
			width: 30px;
			height: 30px;
			line-height: 30px;
			@include font-size(16);
			margin-right: 12px;
			&:after {
				@include font-size(14);
				width: 52px;
				line-height: 22px;
				height: 22px;
				margin-top: -11px;
				left: -46px;
				@include border-radius(11px 0 0 11px);
			}
		}
		>strong {
			@include font-size(15);
			width: calc(100% - 42px);
		}
	}
}


// 

.leadBox {
	margin: 45px auto 25px;
	max-width: 1040px;
	padding: 0 20px;
	.ttl {
		font-weight: bold;
		@include font-size(26);
		padding: 35px 0 50px;
		text-align: center;
		@include max-screen(767px) {
			@include font-size(19);
			padding: 20px 0;
		}
	}
	.img {
		img{display: block;}
	}
	.txtBox {
		border: 2px solid $color_yellow;
		max-width: 820px;
		margin: 0 auto;
		h3 {
			background: $color_yellow;
			color: #fff;
			@include font-size(20);
			font-weight: bold;
			letter-spacing: 0.04em;
			padding: 10px 20px;
			text-align: center;
		}
		ul {
			padding: 16px 55px;
			li {
				display: inline-block;
				width: 50%;
				@include font-size(16);
				padding: 4px 10px 4px 0;
				em {
					font-style: normal;
					font-weight: bold;
					color: $color_yellow;
				}
			}
		}
		@include max-screen(767px) {
			h3 {
				@include font-size(15);
			}
			ul {
				padding: 12px 15px;
				li {
					display: block;
					width: 100%;
					@include font-size(14);
					padding: 2px 0;
				}
			}
		}
	}
	.ttlAnchor {
		@include font-size(22);
		padding: 45px 0 15px;
		text-align: center;
		font-weight: bold;
		@include max-screen(767px) {
			@include font-size(17);
			padding: 30px 0 15px;
		}
	}
	.anchorList {
		margin: -10px -10px 50px;
		text-align: center;
		li {
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			padding: 10px;
			a {
				display: block;
				background: url(../img/service/ico_anchor.svg) no-repeat bottom right;
				background-size: 29px auto;
				background-color:  $color_yellow;
				height: 74px;
				position: relative;
				color: #fff;
				text-align: center;
				@include font-size(18);
				font-weight: bold;
				span {
					line-height: 1.4;
			        display: block;
			        width:100%;
			        position: absolute;
			        top:50%;
			        z-index:100;
			        -webkit-transform: translateY(-50%);
			        -ms-transform: translateY(-50%);
			        transform: translateY(-50%);
			        text-align: center;
					em {color: $color_yellow2; font-style: normal;}
					
				}
			}
		}
		@include max-screen(767px) {
			margin: 0 -3px 25px;
			li {
				width: 50%;
				padding: 3px;
				a {
					height: 61px;
					@include font-size(14);
					span {
						padding: 0 10px;
						text-align: left;
					}
				}
			}
		}
	}
	&.green {
		.txtBox {border: 2px solid $color_green;
			h3 {background: $color_green;}
			ul {li {em {color: $color_green;}}}
		}
		.anchorList {
			li {a {background-color:  $color_green;
					span {em {color: $color_yellow2;}}
				}
			}
		}
	}
	&.blue {
		.txtBox {border: 2px solid $color_blue;
			h3 {background: $color_blue;}
			ul {li {em {color: $color_blue;}}}
		}
		.anchorList {
			li {a {background-color:  $color_blue;
					span {em {color: $color_yellow2;}}
				}
			}
		}
	}
	&.pink {
		.txtBox {border: 2px solid $color_pink;
			h3 {background: $color_pink;}
			ul {li {em {color: $color_pink;}}}
		}
		.anchorList {
			li {a {background-color:  $color_pink;
					span {em {color: $color_yellow2;}}
				}
			}
		}
	}
	&.yellow {
		.txtBox {border: 2px solid $color_yellow;
			h3 {background: $color_yellow;}
			ul {li {em {color: $color_yellow;}}}
		}
		.anchorList {
			li {a {background-color:  $color_yellow;
					span {em {color: $color_yellow2;}}
				}
			}
		}
	}
}

// #rc {.txtBox {max-width: 760px;}}
// #casacube {.txtBox {max-width: 670px;}}
#order .leadBox.pink .anchorList li a {height: 60px;}

// 

.txt_service {
	text-align: center;
	span {
		display: inline-block;
		text-align: center;
		@include font-size(25);
		font-weight: bold;
		line-height: 1.4;
		border-bottom: 2px solid $color_yellow;
		padding: 0 18px 10px;
		em {
			display: inline-block;
			font-style: normal;
			color: $color_yellow;
		}
		i {font-style: normal;}
	}
	@include max-screen(767px) {
		span {
			display: block;
			@include font-size(19);
			padding: 0 0 10px;
			line-height: 1.4;
			i {@include font-size(24);}
		}
	}
	&.green {
		span {border-bottom: 2px solid $color_green;
			em {color: $color_green;}
		}
	}
	&.blue {
		span {border-bottom: 2px solid $color_blue;
			em {color: $color_blue;}
		}
	}
	&.pink {
		span {border-bottom: 2px solid $color_pink;
			em {color: $color_pink;}
		}
	}
	&.yellow {
		span {border-bottom: 2px solid $color_yellow;
			em {color: $color_yellow;}
		}
	}
}

// 
// 

.itemService {
	margin-bottom: 75px;
	font-size: 15px;
	.thumb {
		float: left;
		width: 40%;
		text-align: center;
		background: #E6E6E6;
		img {
			display: block;
			margin: 0 auto;
		}
	}
	.desc {
		float: right;
		width: 56%;
		@include font-size(15);
		line-height: 2;
		> p {
			margin-bottom: 60px;
			&:last-child {margin-bottom: 0;}
		}
		em {
			font-style: normal;
			color: $color_yellow;
			font-weight: bold;
		}
	}
	@include max-screen(767px) {
		margin: 0 0 28px;
		font-size: 14px;
		.thumb {
			float: none;
			width: 100%;
			margin-bottom: 15px;
			background: none;
			img {
				width: 100%;
			}
		}
		.desc {
			float: none;
			width: 100%;
			@include font-size(14);
			> p {
				margin-bottom: 23px;
				&:last-child {margin-bottom: 0;}
			}
		}
	}
	&.green {.desc {em {color: $color_green;}}}
	&.blue {.desc {em {color: $color_blue;}}}
	&.pink {.desc {em {color: $color_pink;}}}
	&.yellow {.desc {em {color: $color_yellow;}}}
}


.sHead1 {
	font-weight: bold;
	@include font-size(19);
	padding-left: 15px;
	border-left: 4px solid $color_yellow;
	margin-bottom: 15px;
	line-height: 1.4;
	em {
		font-style: normal;
		color: $color_yellow;
	}
	@include max-screen(767px) {
		@include font-size(16);
	}
	&.green {border-left: 4px solid $color_green;em {color: $color_green;}}
	&.blue {border-left: 4px solid $color_blue;em {color: $color_blue;}}
	&.pink {border-left: 4px solid $color_pink;em {color: $color_pink;}}
	&.yellow {border-left: 4px solid $color_yellow;em {color: $color_yellow;}}
}

// 

.itemService2 {
	.thumb {
		float: right;
	}
	.desc {
		float: left;
	}
}



// 

.txtLead {
	@include font-size(15);
	margin-bottom: 40px;
	em {color: $color_yellow; font-style: normal;font-weight: bold;}
	@include max-screen(767px) {
		@include font-size(14);
		margin-bottom: 20px;
		margin-top: 10px;
	}
	&.yellow {em{color: $color_yellow;}}
	&.green {em{color: $color_green;}}
	&.blue {em{color: $color_blue;}}
	&.pink {em{color: $color_pink;}}
}
.mamaBox {
	.imgLead {
		text-align: center;
		margin-bottom: 110px;
	}
	@include max-screen(767px) {
		> .txtLead:first-child {margin-top: 0!important;}
		.txtLead {
			@include font-size(14);
			margin-bottom: 20px;
			margin-top: 10px;
		}
		.imgLead {
			margin-bottom: 20px;
		}
	}
}


// 
ul.col3 {
	margin: 0 -10px 60px;
	> li {
		float: left;
		width: 33.33%;
		padding: 0 10px 60px;
		.img {
			margin-bottom: 18px;
			img {display: block; width: 100%; height: auto;}
		}
		.ttl {
			margin-bottom: 15px;
			font-weight: bold;
			> em {
				font-style: normal;
				display: inline-block;
				vertical-align: middle;
				width: 30px;
				height: 30px;
				text-align: center;
				@include font-size(16);
				font-family: 'Helvetica Neue';
				margin-right: 10px;
				color: #fff;
				background: $color_yellow;
				line-height: 30px;
				@include border-radius(50%);
			}
			strong {
				display: inline-block;
				vertical-align: middle;
				@include font-size(19);
				> em {
					font-weight: bold;
					color: $color_yellow;
					font-style: normal;
				}
			}
		}
		.desc {
			@include font-size(14);
			line-height: 1.7;
			h4 {
				line-height: 1.4;
			}
		}
	}
	@include max-screen(767px) {
		margin: 0 0 35px;
		> li {
			float: none;
			width: 100%;
			padding: 0;
			.img {
				margin-bottom: 15px;
				img {width: 100%; height: auto;display: block;}
			}
			.ttl {
				margin-bottom: 0;
				padding: 14px 0;
				border-top: 1px solid #E6E6E6;
				// border-bottom: 1px solid #E6E6E6;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					right: 13px;
					top: 50%;
					height: 2px;
					width: 12px;
					background: #B3B3B3;
				}
				&:before {
					content: '';
					position: absolute;
					right: 18px;
					top: 50%;
					height: 12px;
					width: 2px;
					background: #B3B3B3;
					margin-top: -5px;
				}
				strong {
					@include font-size(16);
				}
				&.active {
					&:before {display: none;}
				}
			}
		}
		&.ul_item li {
			.desc {display: none; padding: 0 15px 15px;}
		}
	}
}

.rc ul.col3>li .desc {
	@include font-size(15);
	@include max-screen(767px) {
		@include font-size(14);
	}
}

// col2
.col2 {
	> li {
		float: left;
		width: 49%;
		margin-bottom: 70px;
		@include font-size(15);
		&:nth-child(even) {float: right;}
		.img {
			margin-bottom: 30px;
			img {display: block;}
		}
	}
	@include max-screen(767px) {
		> li {
			@include font-size(14);
			float: none;
			width: 100%;
			margin-bottom: 35px;
			&:nth-child(even) {float: none;}
			.img {
				margin-bottom: 15px;
				img { width: 100%; }
			}
		}
	}
}



// 
.mamaBox {
	.col2 > li .img {
		margin-bottom: 20px;
	}
	&.mamaBox2 {
		margin-bottom: 110px;
		@include max-screen(767px) {
			margin-bottom: 50px;
		}
		.txtLead2 {
			font-weight: bold;
			@include font-size(19);
			text-align: center;
			margin-bottom: 30px;
			line-height: 1;
			@include max-screen(767px) {
				@include font-size(16);
				margin-bottom: 10px;
				line-height: 1.4;
			}
		}
		.ul_step.col3 {
			> li {
				.ttl {margin-bottom: 0px!important;}
			}
			@include max-screen(767px) {
				> li {
					border-bottom: 1px solid #ccc;
					.ttl {padding: 0;}
				}
			}
		}
	}
	.ul_step.col3 {
		background: url(../img/service/mamabox/ico_arrow.svg) no-repeat center bottom;
		background-size: 150px auto;
		padding-bottom: 53px;
		margin-bottom: 18px;
		li {
			padding-bottom: 0;
			// border-bottom: 1px solid #E6E6E6;
			div {
				background: #F7F7F7;
				@include border-radius(10px);
				padding: 24px;
				.ttl {
					border: none;
					strong {
						font-weight: normal;
						@include font-size(15);
						line-height: 1.4;
						width: calc(100% - 40px);
					}
					&:after, &:before {display: none;}
				}
				.txtLink {
					color: $color_yellow;
					font-weight: bold;
					@include font-size(16);
					text-align: right;
					em {
						font-style: normal;
						color: #B3B3B3;
					}
				}
			}
		}
		@include max-screen(767px) {
			padding-bottom: 47px;
			margin-bottom: 7px;
			li {
				padding-bottom: 0;
				border-bottom: 1px solid #ccc;
				div {
					background: none;
					@include border-radius(0);
					padding: 13px;
					.ttl {
						strong {
							@include font-size(14);
						}
					}
					.txtLink {
						text-align: center;
						@include font-size(15);
					}
				}
			}
		}
	}
	.ul_item2.col3 li {
		padding-bottom: 0;
	}
	@include max-screen(767px) {
		.ul_item2.col3 {
			li {
				.sHead1 {margin-bottom: 10px;}
				padding-bottom: 35px;
				&:last-child {padding-bottom: 0;}
			}
		}
	}
	.txt2 {
		text-align: center;
		span {
			display: inline-block;
			background: #F7F7F7;
			@include border-radius(10px);
			padding: 30px 60px;
			text-align: center;
			@include font-size(17);
			font-weight: bold;
			em {
				font-style: normal;
				color: $color_yellow;
			}
			@include max-screen(767px) {
				padding: 15px;
				@include font-size(15);
				display: block;
			}
		}
	}
	.txt3 {
		text-align: center;
		font-weight: bold;
		@include font-size(19);
		margin: 25px 0 75px;
		&.txt3_1{
			@include font-size(25);
			margin-bottom: 0;
		}
		em {
			font-style: normal;
			color: $color_yellow;
		}
		@include max-screen(767px) {
			@include font-size(15);
			margin: 25px 0 20px;
			&.txt3_1{
				@include font-size(15);
				margin-bottom: 0;
			}
		}
	}
}




// 
.faq_mamabox {
	border-top: 1px solid #ccc;
	padding-top: 75px;
	.txtQ {
		border: 2px solid $color_yellow;
		@include border-radius(10px);
		background: #fff;
		position: relative;
		padding: 15px 15px 15px 55px;
		font-weight: bold;
		@include font-size(18);
		line-height: 1.4;
		margin-bottom: 32px;
		&:after {
			content: 'Q';
			position: absolute;
			top: 50%;
			margin-top: -15px;
			left: 15px;
			@include font-size(16);
			width: 30px;
			height: 30px;
			@include border-radius(50%);
			text-align: center;
			line-height: 30px;
			color: #fff;
			background: #B3B3B3;
			font-family: 'Helvetica Neue';
			font-weight: bold;
		}
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			bottom: -10px;
			width: 18px;
			height: 18px;
			background: #fff;
			border-left: 2px solid $color_yellow;
			border-top: 2px solid $color_yellow;
			-moz-transform: rotate(225deg);
			-webkit-transform: rotate(225deg);
			-ms-transform: rotate(225deg);
			transform: rotate(225deg);
		}
	}
	.txtA {
		position: relative;
		padding-left: 40px;
		@include font-size(15);
		&:after {
			position: absolute;
			content: 'A';
			@include font-size(16);
			font-weight: bold;
			color: #fff;
			text-align: center;
			width: 30px;
			height: 30px;
			line-height: 30px;
			@include border-radius(50%);
			font-family: 'Helvetica Neue';
			left: 0;
			top: 0;
			background: $color_yellow;
		}
	}
	@include max-screen(767px) {
		border-top:none;
		padding-top: 0;

		.itemService {
			.thumb {
				margin: 20px 0 0;
			}
		}
		.txtQ {
			padding: 10px 15px 10px 55px;
			@include font-size(15);
			margin-bottom: 24px;
			min-height: 50px;
		}
		.txtA {
			position: relative;
			padding-left: 55px;
			@include font-size(15);
			&:after {
				left: 15px;
				top: 0;
			}
		}
	}
}


.calendar.mamaBox {
	.col2 {
		> li {
			.img {margin-bottom: 0;}
			@include max-screen(767px) {
				margin-bottom: 0;
			}
		}
	}
	.itemService {
		.desc {
			margin-bottom: 20px;
			p {
				margin-bottom: 35px;
				&:last-child {margin-bottom: 0;}
				@include max-screen(767px) {
					margin-bottom: 25px;
					&:last-child {margin-bottom: 0;}
				}
			}
		}
		@include max-screen(767px) {
			.thumb {margin-bottom: 0;}
		}
	}
}
// 


.itemService3 {
	@include max-screen(767px) {
		.thumb {
			margin: 15px 0 0;
		}
	}
}

// 
.itemStep {
	padding: 35px 0 35px 103px;
	.itemService {margin-bottom: 0;margin-top: 0;}
	ul {
		li {
			float: left;
			width: 44.6%;
			@include font-size(14);
			&:nth-child(even) {
				float: right;
			}
			em {color: $color_yellow; font-style: normal;font-weight: bold;}
			h3 {
				text-align: center;
				@include font-size(16);
				margin-bottom: 10px;
				font-weight: bold;
				line-height: 1.4;
				position: relative;
				&:before {
					display: inline-block;
					vertical-align: middle;
					content: '';
					width: 1px;
					height: 100%;
				}
				span {
					display: inline-block;
					vertical-align: middle;
					width: 99%;
				}
			}
			.img {margin-bottom: 10px;img{display: block;}}
		}
	}
	@include max-screen(767px) {
		padding: 24px 0 24px;
		.itemService {margin-bottom: 0;
			.desc, .inner {
				padding: 0 20px;
			}
			.thumb {
				margin: 17px 0 0;
			}
		}
		ul {
			padding: 0 20px;
			li {
				float: none;
				width: 100%;
				@include font-size(12);
				border-bottom: 1px solid #ccc;
				padding-bottom: 15px;
				margin-bottom: 20px;
				&:nth-child(even) {
					float: none;
				}
				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}
				h3 {
					@include font-size(15);
				}
				.img img { width: 100%; }
			}
		}
	}
}


.listHouseItem {
	h4 {
		text-align: center;
		margin: 25px 0 15px;
		font-size: 14px;
		span {
			display: inline-block;
			padding: 0 25px;
			position: relative;
			font-weight: bold;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: #1E1E1E;
				z-index: 1;
			}
			em {
				font-style: normal;
				background: #fff;
				display: inline-block;
				padding: 0 7px;
				position: relative;
				z-index: 2;
			}
		}
	}
	ul {
		text-align: center;
		margin: 0 -4px;
		li {
			display: inline-block;
			vertical-align: top;
			width: 33.33%;
			@include font-size(12);
			padding: 0 4px;
			margin: 0;
			img {display: block;margin-bottom: 14px;}
		}
	}
	@include max-screen(767px) {
		ul {
			margin: 0 -3px;
			li {
				padding: 0 3px;
				margin: 0;
				img {margin-bottom: 10px; width: 100%;}
			}
		}
	}
}



// 

.homeBoxStyle {
	.col3 {
		margin-bottom: 30px;
		li {
			padding-bottom: 0;
			.img {margin-bottom: 15px;img{display: block;}}
			h4 {
				font-weight: bold;
				@include font-size(15);
			}
		}
		@include max-screen(767px) {
			margin-bottom: 0;
			li {
				padding-bottom: 20px;
				.img {margin-bottom: 12px;}
				h4 {
					@include font-size(14);
				}
			}
		}
	}
	.txtNote {@include font-size(12);margin-bottom: 70px;@include max-screen(767px) {margin-bottom: 50px;}}
	.col4 {
		margin: -45px -10px 30px;
		li {
			float: left;
			width: 25%;
			padding: 45px 10px 0;
			.img {
				margin-bottom: 15px;
				img {display: block;}
			}
			h4 {
				font-weight: bold;
				@include font-size(15);
			}
			em {
				display: block;
				img{display: block;width: 100%;height: auto;}
			}
			span {
				display: block;
				@include font-size(14);
				margin-top: 12px;
			}
		}
		@include max-screen(767px) {
			margin: 0;
			li {
				float: left;
				width: 47.8%;
				padding: 0 0 20px;
				&:nth-child(even) {float: right;}
				.img {
					margin-bottom: 10px;
					img { width: 100%; }
				}
				h4 {
					@include font-size(14);
				}
			}
		}
	}
}



.ul_col3_thumb {
	text-align: center;
	margin: 45px 0 95px;
	li {
		display: inline-block;
		vertical-align: top;
		@include font-size(12);
		padding: 0 5px;
		img {
			display: block;
			margin-bottom: 20px;
		}
	}
	@include max-screen(767px) {
		margin: 20px -2px 40px;
		li {
			display: block;
			padding: 0 2px;
			float: left;
			width: 33.33%;
			img {
				margin-bottom: 10px;
			}
		}
	}
}
.itemService4 {
	@include max-screen(767px) {
		margin-bottom: 0;
		.thumb {margin: 0;}
		.desc {
			padding: 20px;
		}
	}
}



// contact
.contact_box {
	background: rgba(250,159,12,0.2);
	margin-top: 50px;
	.ttl {
		background: $color_yellow;
		position: relative;
		color: #fff;
		text-align: center;
		font-weight: bold;
		@include font-size(24);
		padding: 23px;
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			bottom: -24px;
			width: 0;
			height: 0;
			border-bottom: 20px solid transparent;
			border-left: 14px solid $color_yellow;
			border-top: 20px solid transparent;
			-moz-transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
			z-index: 2;
		}
	}
	.inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 0 20px 6px;
		h3 {
			margin-top: -10px;
			position: relative;
			z-index: 1;
			width: 109px;
		}
		.txt {
			font-weight: bold;
			@include font-size(17);
			font-weight: bold;
			padding: 10px 0 13px;
		}
		ul {
			li {
				float: left;
				@include max-screen(768px) {
					display: table;
				}
				&:nth-child(1) {
					width: 36.5%;
					float: right;
					text-align: right;
					position: relative;
					z-index: 1;
					img {
						margin-top: -88px;
						margin-right: -10px;
					}
				}
				&:nth-child(2) {
					width: 31.5%;
					a {
						display: block;
						@include border-radius(5px);
						overflow: hidden;
						p {
							@include font-size(33);
							color: #1E1E1E;
							text-align: left!important;
							font-weight: 600;
							letter-spacing: 0.05em;
							line-height: 1em;
							padding: 0 0 0 50px;
							margin-top: 10px;
							position: relative;
							em {
								font-style: normal;
								font-family: 'YuGothic', 'Hiragino Kaku Gothic ProN', helvetica, sans-serif;
								font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Yu Gothic", YuGothic, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
								font-feature-settings: "pkna";
							}
							@include min-screen(768px) {
								@include font-size(24);
								text-align: left;
							}
							@include min-screen(992px) {
								@include font-size(33);
								text-align: center;
							}
							@include max-screen(767px) {
								background: #fff;
								line-height: 1.2em;
								@include font-size(28);
								padding: 10px 0 5px 30px;
								margin-top: 0;
								text-align: center!important;
							}
							@include max-screen(320px) {
								@include font-size(22);
							}
							
							&:before {
								content: '';
								background: url('../img/common/ico_tel.svg') no-repeat 0 0;
								background-size: 39px 26px;
								width: 39px;
								height: 26px;
								position: absolute;
								top: 4px;
								left: 0;
								@include max-screen(767px) {
									top: 50%;
									left: 30px;
									margin-top: -16px;
									background-size: 32px 21px;
									width: 32px;
									height: 21px;
								}
								@include max-screen(320px) {
									left: 20px;
									margin-top: -10px;
								}
							}
							span {
								display: block;
								@include font-size(13);
								font-weight: 300;
								letter-spacing: 0;
								text-align: left;
								padding: 0 0 0 10px;
								@include min-screen(768px) {
									@include font-size(11);
								}
								@include min-screen(992px) {
									@include font-size(13);
								}
								@include max-screen(767px) {
									text-align: center;
									padding: 0;
									line-height: 1.4em;
									margin: 0 0 0 -30px;
								}
								@include max-screen(320px) {
									@include font-size(11);
								}
							}
						}
						small {
							@include font-size(12);
							text-align: center;
							display: none;
							background: #585858;
							color: #fff;
							@include max-screen(767px) {
								display: block;
							}
						}
					}
				}
				&:nth-child(3) {
					width: 32%;
					a {
						display: block;
						padding: 12px 21px 0 70px;
						background: #EB3953;
						color: #fff;
						height: 74px;
						@include border-radius(5px);
						overflow: hidden;
						position: relative;
						@include max-screen(767px) {
							display: table-cell;
							width: 100%;
							vertical-align: middle;
							padding: 0 20px 0 60px;
						}
						&:before {
							content: '';
							background: url('../img/common/ico_mail.svg') no-repeat 0 0;
							background-size: 31px 25px;
							width: 31px;
							height: 25px;
							position: absolute;
							top: 50%;
							left: 25px;
							margin-top: -12.5px;
						}
						small {
							display: block;
							@include font-size(13);
							font-weight: 600;
							@include min-screen(768px) {
								@include font-size(11);
							}
							@include min-screen(992px) {
								@include font-size(13);
							}
							@include max-screen(320px) {
								@include font-size(10);
							}
						}
						span {
							@include font-size(20);
							font-weight: 600;
							@include min-screen(768px) {
								@include font-size(18);
							}
							@include min-screen(992px) {
								@include font-size(20);
							}
							@include max-screen(320px) {
								@include font-size(16);
							}
						}
					}
				}
			}
		}
	}

	&.green {
		background: rgba(54,207,198,0.2);
		.ttl {
			background: $color_green;
			&:after {
				border-left: 14px solid $color_green;
			}
		}
	}
	@include max-screen(767px) {
		margin-top: 25px;
		.ttl {
			@include font-size(21);
			padding: 15px 20px;
			span {
				display: block;
				@include font-size(15);
			}
		}
		.inner {
			padding: 0 30px 30px;
			ul {
				li {
					padding-top: 15px;
					float: none;
					&:nth-child(1) {
						width: 100%;
						float: none;
						text-align: center;
						img {
							margin-top: 0;
							margin-right: 0;
						}
					}
					&:nth-child(2) {
						width: 100%;
					}
					&:nth-child(3) {
						width: 100%;
					}
				}
			}
		}
	}
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.mamaBox .ul_step.col3 li div .txtLink {
		@include font-size(12);
		letter-spacing: -0.5px;
	}
	.contact_box .inner ul li:nth-child(3) a {padding-right: 10px;}
}




#mamabox {
	.footer__boxContact {display: none;}
	@include max-screen(768px) {
		.mt_sp10 {margin-top: 10px!important;}
	}
	.taC {text-align: center;}
}




// order page
#order {
	.orderItem {
		border-top: 1px solid #ccc;
		padding-top: 85px;
		margin-top: 85px;
		margin-bottom: 30px;
		@include max-screen(767px) {
		    border-top: none;
		    padding-top: 0;
		    margin-top: 50px;
		    margin-bottom: 30px;
		}
	}
	.col2>li {
		margin-bottom: 0; .img {margin-bottom: 0;}
		@include max-screen(767px) {
			margin-bottom: 15px;
			&:last-child{margin-bottom: 0;}
		}
	}
	.txtList {
		li {
			border: 1px solid #ccc;
			@include border-radius(10px);
			padding: 30px;
			h3 {
				font-weight: bold;
				@include font-size(16);
				padding-bottom: 10px;
			}
		}
		@include max-screen(767px) {
			li {
			    padding: 20px;
			    margin-bottom: 15px;
			    &:last-child {margin-bottom: 0;}
				h3 {
					@include font-size(15);
				}
			}
		}
	}
	.txtNote {
		@include font-size(12);
		margin-top: 20px;
		margin-bottom: 50px;
	}
}

// bnrNav
.bnrNav {
	background: #F7F7F7;
	padding: 35px 0;
	margin-top: 80px;
	h3 {
		font-weight: bold;
		@include font-size(18);
		text-align: center;
		padding-bottom: 18px;
		line-height: 1;
	}
	ul {
		text-align: center;
		max-width: 1054px;
		margin: 0 auto;
		padding: 0 20px;
		li {
			float: left;
			width: 33.33%;
			padding: 0 7px;
			a {
				display: block;
				background: $color_yellow;
				@include border-radius(5px);
				position: relative;
				&:after {
					content: "";
					position: absolute;
					right: 12px;
					top: 50%;
					margin-top: -3px;
					width: 6px;
					height: 6px;
					border-left: 1px solid #fff;
					border-top: 1px solid #fff;
					-moz-transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					-ms-transform: rotate(135deg);
					transform: rotate(135deg);
					z-index: 2;
				}
				span {
					display: inline-block;
					vertical-align: middle;
					width: 43.5%;
					img {
						display: block;
						width: 100%;
						height: auto;
					}
				}
				strong {
					display: inline-block;
					vertical-align: middle;
					width: 56.5%;
					color: #fff;
					padding: 10px 10px 10px 18px;
					text-align: left;
					@include font-size(12);
					font-weight: bold;
					em{
						font-style: normal;
						@include font-size(16);
						display: block;
						margin-top: 3px;
					}
				}
			}
			&.yellow a {background: $color_yellow;}
			&.green a {background: $color_green;}
			&.blue a {background: $color_blue;}
			&.pink a {background: $color_pink;}
		}
	}
	@include max-screen(767px) {
		    background: none;
		    padding: 44px 30px 0;
		    margin-top: 0;
		h3 {
    		@include font-size(16);
		}
		ul {
			padding: 0;
			li {
			    float: none;
			    width: 100%;
			    padding: 0;
			    margin-bottom: 14px;
			    &:last-child {margin-bottom: 0;}
				a {
					strong {
						padding: 0 10px 0 15px;
						em{
							@include font-size(18);
						}
					}
				}
			}
		}
	}
}
#casacube .bnrNav .green,
#rc .bnrNav .blue,
#order .bnrNav .pink,
#mamabox .bnrNav .yellow {display: none!important;}
#mamabox {
	.bnrNav {margin-top: 0;}
	@include max-screen(767px) {
		.footer__boxLink__linkSP ul.main-link li:first-child {border-top: 1px solid #E6E6E6;}
	}
	}

// rc page
#rc {
	.ul_item2.blue {margin-bottom: 0;}
	.rcBox {
		@include font-size(15);
		margin-top: 45px;
		margin-bottom: 50px;
		.thumb {
			float: right;
			width: 32%;
			span {
				display: block;
				margin-top: 5px;
			}
		}
		.desc {
			float: left;
			width: 66%;
			line-height: 2;
		}
		em {
			font-style: normal;
			font-weight: bold;
			color: $color_blue;
		}
		@include max-screen(767px) {
			@include font-size(14);
			margin-top: 30px;
			margin-bottom: 30px;
			.thumb {
				float: none;
				width: 100%;
				margin-top: 45px;
				text-align: center;
				img {
					width: 100%;
				}
				span {
					display: block;
					margin-top: 5px;
					text-align: left;
				}
			}
			.desc {
				float: none;
				width: 100%;
			}
		}
	}
	.rcItem {margin-bottom: 30px;}
	.col5 {
		margin: 0 -10px 75px;
		li {
			float: left;
			width: 20%;
			padding: 0 10px;
			strong {
				display: block;
				font-weight: bold;
				@include font-size(15);
				margin: 12px 0;
			}
			span {
				display: block;
				@include font-size(14);
				margin-top: 8px;
			}
		}
		@include max-screen(767px) {
			margin: 0;
			li {
				float: left;
				width: 46.4%;
				padding: 0 0 40px;
				em {
					img { width: 100%; }
				}
				&:nth-child(even) {float: right;}
				&:nth-child(5) {
					width: 100%;
					em {
						display: block;
						width: 46.4%;
						float: left;
						margin-right: 20px;
					}
					strong {margin-top: 0;}
				}
			}
		}
	}
	.rc_col3 {
		@include max-screen(767px) {
			li {
				margin-bottom: 35px;
			}
		}
	}
	.col2.last {
		li {
			margin-bottom: 0;
			.img {margin-bottom: 20px;}
			@include max-screen(767px) {
				margin-bottom: 35px;
				&:last-child {margin-bottom: 0;}
			}
		}
	}
	@include max-screen(767px) {
		.txt_service#anchor2 {letter-spacing: -0.5px;}
	}

	.rcHome {
		.col4 {
			margin-top: 0px;
			li {
				padding-top: 0;
			}
		}
	}
}

// 
.mb0 {margin-bottom: 0px!important;}

.ul_item2.yellow li .desc p em {font-style: normal; font-weight: bold; color: $color_yellow;}
.ul_item2.green li .desc p em {font-style: normal; font-weight: bold; color: $color_green;}
.ul_item2.blue li .desc p em {font-style: normal; font-weight: bold; color: $color_blue;}
.ul_item2.pink li .desc p em {font-style: normal; font-weight: bold; color: $color_pink;}


.yellow.col2 li .desc em {font-style: normal; font-weight: bold; color: $color_yellow;}
.blue.col2 li .desc em {font-style: normal; font-weight: bold; color: $color_blue;}
.green.col2 li .desc em {font-style: normal; font-weight: bold; color: $color_green;}
.pink.col2 li .desc em {font-style: normal; font-weight: bold; color: $color_pink;}

.img13 {
	@include max-screen(767px) {
		text-align: center;
		img {
			width: 100%;
		}
	}
}


