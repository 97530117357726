.staff {
	.footer {
		margin: 135px 0 0;
		@include max-screen(767px) {
			margin: 95px 0 0;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/staff/staff_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/staff/staff_mainvisual_sp.jpg') no-repeat center top;
			}
		}
	}
	&__article {
		&__boxInfo {
			margin: 50px 0 0;
			@include max-screen(767px) {
				margin: 30px 0 0;
			}
			figure {
				line-height: 0;
				@include max-screen(767px) {
					margin: 0 -15px;
				}
				img {
					width: 100%;
				}
			}
			h2 {
				@include font-size(26);
				letter-spacing: 0.04em;
				line-height: 1.76em;
				font-weight: 600;
				text-align: center;
				margin: 50px 0 0;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.52em;
					margin: 25px 0 0;
					text-align: left;
				}
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				text-align: center;
				margin: 20px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.71em;
					margin: 10px 0 0;
					text-align: left;
				}
			}
		}
		&__boxStaff {
			margin: 30px 0 0;
			@include max-screen(767px) {
				margin: 25px 0 0;
			}
			&__itemWrap {
				@include clearfix();
				margin: 0 -10px;
				&__item {
					padding: 0 10px;
					margin: 20px 0 0;
					@include max-screen(767px) {
						margin: 10px 0 0;
					}
					&__inner {
						background: #F7F7F7;
						padding: 30px;
						@include max-screen(767px) {
							padding: 15px;
						}
					}
					&__boxInfo {
						@include clearfix();
						figure {
							float: left;
							width: 136px;
							@include max-screen(320px) {
								width: 90px;
							}
							img {
								width: 100%;
							}
						}
						&__des {
							float: left;
							width: calc(100% - 136px);
							padding: 5px 0 0 20px;
							@include max-screen(767px) {
								padding: 5px 0 0 15px;
							}
							@include max-screen(320px) {
								width: calc(100% - 90px);
							}
							h3 {
								@include font-size(19);
								font-weight: 600;
								letter-spacing: 0.04em;
								border-bottom: 1px solid #B3B3B3;
								padding: 0 0 10px;
								@include max-screen(767px) {
									@include font-size(17);
								}
							}
							&__info {
								margin: 15px 0 0;
								@include max-screen(320px) {
									margin: 10px 0 0;
								}
								.item {
									display: block;
									padding: 2px 0;
									@include clearfix();
									p {
										@include font-size(12);
										line-height: 1.66em;
										display: block;
										&.ttl {
											color: #1594B0;
											font-weight: 600;
											padding: 0 10px 0 0;
											width: 40px;
											float: left;
											@include max-screen(320px) {
												padding: 0 5px 0 0;
											}
										}
										&.txt {
											float: left;
											width: calc(100% - 40px);
										}
									}
								}
							}
						}
					}
					&__boxText {
						margin: 10px 0 0;
						@include max-screen(767px) {
							margin: 13px 0 0;
						}
						p {
							@include font-size(15);
							line-height: 1.86em;
							@include max-screen(767px) {
								@include font-size(14);
								line-height: 1.71em;
							}
						}
					}
				}
			}
		}
	}
}