@charset 'UTF-8';


/*-----------------------------------------------------------
Header
-------------------------------------------------------------*/
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	background: #fff;
	z-index: 9998;
	@include max-screen(767px) {
		height: 54px;
	}
	&__boxInfo {

	}
	&__boxLogo {
		float: left;
		padding: 0 0 0 180px;
		@include min-screen(768px) {
			padding: 0 0 0 15px;
		}
		@include min-screen(992px) {
			padding: 0 0 0 50px;
		}
		@include min-screen(1200px) {
			padding: 0 0 0 100px;
		}
		@include min-screen(1300px) {
			padding: 0 0 0 180px;
		}
		@include max-screen(767px) {
			padding: 0 0 0 15px;
		}
		.logo {
			line-height: 74px;
			@include max-screen(767px) {
				line-height: 54px;
			}
			img {
				vertical-align: middle;
				@include min-screen(768px) {
					width: 80px;
				}
				@include min-screen(992px) {
					width: 100px;
				}
				@include min-screen(1200px) {
					width: 124px;
				}
				@include max-screen(767px) {
					width: 98px;
				}
			}
		}
	}
	&__boxGnavi {
		float: right;
		&__gnavi {
			float: left;
			padding: 0 240px 0 0;
			@include min-screen(768px) {
				padding: 0 160px 0 0;
			}
			@include min-screen(992px) {
				padding: 0 180px 0 0;
			}
			@include min-screen(1200px) {
				padding: 0 240px 0 0;
			}
			nav {
				ul {
					@include clearfix();
					li {
						display: inline-block;
						padding: 0 15px;
						@include min-screen(768px) {
							padding: 0 7px;
						}
						@include min-screen(992px) {
							padding: 0 10px;
						}
						@include min-screen(1200px) {
							padding: 0 15px;
						}
						a {
							@include font-size(14);
							font-weight: 600;
							line-height: 74px;
							letter-spacing: 0.025em;
							position: relative;
							top: 5px;
							@include min-screen(768px) {
								@include font-size(11);
								top: 4px;
							}
							@include min-screen(992px) {
								@include font-size(12);
							}
							@include min-screen(1200px) {
								@include font-size(14);
							}
						}
					}
				}
			}
		}
		&__gnaviSP {
			@include max-screen(767px) {
				display: none;
				position: fixed;
				top: 54px;
				left: 0;
				width: 100%;
				height: 100%;
				background: #EFEFEF;
				padding: 15px;
				overflow-y: auto;
				z-index: 9999;
				&--scroll {
					padding: 0 0 55px;
				}
				&__modelHouse {
					border: 1px solid #CBCBCB;
					@include border-radius(5px);
					background: #fff;
					overflow: hidden;
					padding: 25px 0 17px;
					.ttl {
						text-align: center;
						@include font-size(17);
						color: #1E1E1E;
						font-weight: 600;
						line-height: 1em;
						letter-spacing: 0.05em;
					}
					&__list {
						@include clearfix();
						padding: 0 12px;
						li {
							width: 50%;
							float: left;
							padding: 0 5px;
							margin: 17px 0 0;
							a {
								display: block;
								position: relative;
								figure {
									line-height: 0;
									img {
										width: 100%;
									}
								}
								.ttl {
									@include font-size(14);
									color: #fff;
									line-height: 40px;
									span {
										position: relative;
										padding: 0 15px 0 0;
										&:before {
											content: '';
											background: url('../img/common/ico_arrow_w.svg') no-repeat 0 0;
											background-size: 6px 9px;
											width: 6px;
											height: 9px;
											position: absolute;
											top: 50%;
											right: 0;
											margin-top: -4.5px;
										}
									}
								}
							}
							&.box1 {
								a {
									.ttl {
										background: #36CFC6;
									}
								}
							}
							&.box2 {
								a {
									.ttl {
										background: #FA9F0C;
									}
								}
							}
							&.box3 {
								a {
									.ttl {
										background: #25BDE7;
									}
								}
							}
							&.box4 {
								a {
									.ttl {
										background: #FA86AF;
									}
								}
							}
						}
					}
				}
				&__boxList {
					margin: 12px 0 0;
					&__list {
						@include clearfix();
						margin: 0 -4px;
						li {
							float: left;
							width: 33.333%;
							padding: 0 4px;
							a {
								display: block;
								border: 1px solid #CBCBCB;
								@include border-radius(5px);
								background: #fff;
								padding: 15px 0 10px;
								height: 75px;
								position: relative;
								span {
									display: block;
									text-align: center;
								}
								.ttl {
									@include font-size(14);
									color: #000;
									font-weight: 600;
									text-align: center;
									position: absolute;
									bottom: 8px;
									left: 50%;
									width: 100%;
									@include translate(-50%,0);
									@include max-screen(320px) {
										@include font-size(11);
									}
									span {
										position: relative;
										padding: 0 10px 0 0;
										display: inline-block;
										&:before {
											content: '';
											background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
											background-size: 6px 9px;
											width: 6px;
											height: 9px;
											position: absolute;
											top: 50%;
											right: 0;
											margin-top: -4.5px;
										}
									}
								}
							}
						}
					}
				}
				&__boxLink {
					margin: 12px 0 0;
					&__link {
						border: 1px solid #CBCBCB;
						@include border-radius(5px);
						overflow: hidden;
						background: #fff;
						li {
							float: left;
							width: 50%;
							border-bottom: 1px solid #CBCBCB;
							a {
								@include font-size(14);
								color: #0A0A0A;
								border-right: 1px solid #CBCBCB;
								display: block;
								text-align: left;
								line-height: 51px;
								padding: 0 10px;
								position: relative;
								@include max-screen(320px) {
									@include font-size(12);
								}
								&:before {
									content: '';
									background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
									background-size: 6px 9px;
									width: 6px;
									height: 9px;
									position: absolute;
									top: 50%;
									right: 15px;
									margin-top: -4.5px;
									@include max-screen(320px) {
										right: 10px;
									}
								}
							}
							&:nth-of-type(2n) {
								a {
									border-right: none;
								}
							}
							&:nth-of-type(3),
							&:nth-of-type(4) {
								border-bottom: none;
							}
						}
					}
					&__link02 {
						text-align: center;
						margin: 20px 0 0;
						li {
							display: inline-block;
							padding: 0 10px;
							a {
								@include font-size(13);
								color: #0A0A0A;
								text-decoration: underline;
								span {
									position: relative;
									padding: 0 10px 0 0;
									&:before {
										content: '';
										background: url('../img/common/ico_arrow_b.svg') no-repeat 0 0;
										background-size: 6px 9px;
										width: 6px;
										height: 9px;
										position: absolute;
										top: 50%;
										right: 0;
										margin-top: -4.5px;
									}
								}
							}
						}
					}
				}
				&__boxContact {
					margin: 30px 0 0;
					padding: 0 15px;
					&__boxmail {
						display: table;
						width: 100%;
						a {
							display: table-cell;
							vertical-align: middle;
							padding: 0 10px 0 60px;
							background: #EB3953;
							color: #fff;
							height: 74px;
							@include border-radius(5px);
							overflow: hidden;
							position: relative;
							&:before {
								content: '';
								background: url('../img/common/ico_mail.svg') no-repeat 0 0;
								background-size: 31px 25px;
								width: 31px;
								height: 25px;
								position: absolute;
								top: 50%;
								left: 13px;
								margin-top: -12.5px;
							}
							small {
								display: block;
								@include font-size(13);
								font-weight: 600;
								@include max-screen(320px) {
									@include font-size(10);
								}
							}
							span {
								@include font-size(20);
								font-weight: 600;
								@include max-screen(320px) {
									@include font-size(16);
								}
							}
						}
					}
					&__boxtel {
						margin: 15px 0 0;
						a {
							display: block;
							@include border-radius(5px);
							overflow: hidden;
							background: #fff;
							p {
								@include font-size(28);
								font-family: YuGothic, 'YuGothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
								color: #1E1E1E;
								text-align: center;
								font-weight: 600;
								letter-spacing: 0.05em;
								line-height: 1em;
								padding: 10px 0 0 40px;
								position: relative;
								@include max-screen(767px) {
									padding: 10px 0 0 30px;
								}
								&.font-mobile {
									font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Yu Gothic", YuGothic, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
									font-feature-settings: "pkna";
									letter-spacing: 0;
								}
								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								   /* IE10+ CSS */
								   font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
								}
								@supports (-ms-accelerator:true) {
								   /* IE Edge 12+ CSS */ 
								   font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
								}
								&:before {
									content: '';
									background: url('../img/common/ico_tel.svg') no-repeat 0 0;
									background-size: 39px 26px;
									width: 39px;
									height: 26px;
									position: absolute;
									top: 50%;
									left: 26px;
									margin-top: -10px;
									@include max-screen(767px) {
										background-size: 32px 21px;
										width: 32px;
										height: 21px;
										left: 34px;
										margin-top: -20px;
									}
									@include max-screen(320px) {
										left: 20px;	
									}
								}
								span {
									display: block;
									@include font-size(13);
									font-weight: 300;
									letter-spacing: 0;
									@include max-screen(767px) {
										margin: 0 0 0 -30px;
									}
									@include max-screen(320px) {
										@include font-size(11);
									}
								}
							}
							small {
								@include font-size(12);
								text-align: center;
								display: block;
								background: #585858;
								color: #fff;
							}
						}
					}
				}
			}
		}
		&__boxLocation {
			position: fixed;
			top: 13px;
			right: 160px;
		}
		&__boxContact {
			position: absolute;
			top: 0;
			right: 0;
			max-width: 240px;
			width: 100%;
			@include min-screen(768px) {
				max-width: 160px;
			}
			@include min-screen(992px) {
				max-width: 180px;
			}
			@include min-screen(1200px) {
				max-width: 240px;
			}
			@include max-screen(767px) {
				width: 88px;
				right: 54px;
				position: fixed;
			}
			ul {
				@include clearfix();
				li {
					float: left;
					width: 50%;
					@include max-screen(767px) {
						width: 100%;
					}
					a {
						@include font-size(14);
						color: #fff;
						line-height: 74px;
						text-align: center;
						display: block;
						padding: 0 5px;
						&.btn1 {
							background: #EB3953;
							&:hover {
								opacity: 1;
								background-image: -webkit-linear-gradient(#EB3953, #C91A33);
								background-image: -o-linear-gradient(#EB3953, #C91A33);
								background-image: linear-gradient(#EB3953, #C91A33);
							}
						}
						&.btn2 {
							background: #585858;
							&:hover {
								opacity: 1;
								background-image: -webkit-linear-gradient(#585858, #1C1C1C);
								background-image: -o-linear-gradient(#585858, #1C1C1C);
								background-image: linear-gradient(#585858, #1C1C1C);
							}
						}
						@include min-screen(768px) {
							@include font-size(11);
						}
						@include min-screen(992px) {
							@include font-size(12);
						}
						@include min-screen(1200px) {
							@include font-size(14);
						}
						@include max-screen(767px) {
							line-height: 54px;
						}
					}
				}
			}
		}
		&__boxMenubtn {
			float: right;
			position: fixed;
			top: 0;
			right: 0;
			.menu-btn {
				border: none;
				outline: none;
				cursor: pointer;
				background: #585858;
				z-index: 9997;
				width: 54px;
				height: 54px;
				padding: 23px 14px;
				position: relative;
				@include transition(all 0.3s);
				span {
					font-family: YuGothic, 'YuGothic', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
					@include font-size(9);
					color: #fff;
					text-transform: uppercase;
					display: block;
					text-align: center;
					position: absolute;
					top: 5px;
					left: 13px;
				}
				.burger-icon {
					@include burger(26px,2px,6px,#fff);
				}
				&.is-active {
					z-index: inherit;
					.burger-icon {
						@include burger-to-cross;
						z-index: 9999;
					}
				}
			}
		}
	}
}