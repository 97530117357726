.recruit {
	.totop {
		&.active {
			bottom: 20px;
		}
	}
	.subpage {
		&__mainvisual {
			background: url('../img/recruit/recruit_mainvisual.jpg') no-repeat center top;
			background-size: cover;
			@include max-screen(767px) {
				background: url('../img/recruit/recruit_mainvisual_sp.jpg') no-repeat center top;
			}
		}
		&__boxContact {
			&__ttl {
				h3 {
					@include max-screen(767px) {
						@include font-size(18);
					}
				}
			}
		}
		&__boxForm {
			&__tblContact {
				tr {
					td {
						.form-control {
							&.size_s {
								@include max-screen(767px) {
									width: 105px;
								}
							}
						}
					}
				}
			}
		}
	}
	&__title {
		h3 {
			@include font-size(25);
			font-weight: 600;
			text-align: center;
			border-bottom: 2px solid #1594B0;
			padding: 0 0 15px;
			@include max-screen(767px) {
				@include font-size(20);
				padding: 0 0 10px;
			}
		}
	}
	&__h4 {
		font-weight: 600;
		@include font-size(19);
		line-height: 1.4em;
		position: relative;
		padding: 0 0 0 15px;
		@include max-screen(767px) {
			@include font-size(17);
			line-height: 1.5em;
		}
		&:before {
			content: '';
			width: 4px;
			height: 24px;
			background: #1594B0;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&__article {
		&__boxInfo {
			margin: 50px 0 0;
			@include max-screen(767px) {
				margin: 30px 0 0;
			}
			figure {
				line-height: 0;
				@include max-screen(767px) {
					margin: 0 -15px;
				}
				img {
					width: 100%;
				}
			}
			h2 {
				@include font-size(26);
				letter-spacing: 0.04em;
				line-height: 1.76em;
				font-weight: 600;
				text-align: center;
				margin: 50px 0 0;
				@include max-screen(767px) {
					@include font-size(17);
					line-height: 1.52em;
					margin: 25px 0 0;
					text-align: left;
				}
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				text-align: center;
				margin: 20px 0 0;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.71em;
					margin: 10px 0 0;
					text-align: left;
				}
			}
		}
		&__introduce {
			margin: 80px 0 0;
			@include max-screen(767px) {
				margin: 50px 0 0;
			}
			&__inner {
				padding: 0 20px;
				margin: 35px 0 0;
				@include max-screen(767px) {
					padding: 0;
					margin: 20px 0 0;
				}
			}
			&__tbl {
				margin: 25px 0 0;
				@include max-screen(767px) {
					border: 1px solid #E6E6E6;
				}
				tr {
					border-top: 1px solid #E6E6E6;
					@include max-screen(767px) {
						border: none;
					}
					&:last-child {
						border-bottom: 1px solid #E6E6E6;
						@include max-screen(767px) {
							border: none;
						}
					}
					th {
						background: #F7F7F7;
						@include font-size(14);
						width: 13.5%;
						vertical-align: top;
						text-align: left;
						font-weight: 600;
						padding: 16px 10px;
						@include max-screen(767px) {
							float: left;
							width: 100%;
							display: block;
							padding: 9px 15px;
							line-height: 1.7em;
						}
					}
					td {
						@include font-size(15);
						line-height: 1.86em;
						padding: 16px 30px;
						@include max-screen(767px) {
							float: left;
							display: block;
							width: 100%;
							@include font-size(14);
							padding: 9px 15px;
							line-height: 1.7em;
						}
						p {
							margin: 0 0 30px;
							@include max-screen(767px) {
								margin: 0 0 20px;
							}
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
		}
		&__entry {
			margin: 100px 0 0;
			@include max-screen(767px) {
				margin: 40px 0 0;
			}
			p {
				@include font-size(15);
				line-height: 1.86em;
				max-width: 800px;
				margin: 25px auto 0;
				text-align: left;
				@include max-screen(767px) {
					@include font-size(14);
					line-height: 1.7em;
					margin: 20px auto 0;
				}
			}
		}
	}
}